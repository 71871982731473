import { PUBLISHED_DAYS_AGO, PUBLISHED_HOURS_AGO, PUBLISHED_ON_DAY_MONTH_YEAR } from "locales/shiftClaimDetails/es";

export function allNonEmpty(...strings: string[]): boolean {
    return strings.every(str => str && str !== '');
}

export function allFalse(...booleans: boolean[]): boolean {
    return booleans.every(bool => !bool);
}

export function formatTimeSince(dateUTC: string) {
    const now = new Date();
    const past = new Date(dateUTC);

    // Ensure both are treated as UTC
    const diffMs = now.getTime() - past.getTime();
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const diffHours = Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (diffDays > 7) {
        // Format the date as "Publicado el 7 de Mayo de 2025"
        const day = past.getUTCDate();
        const month = past.toLocaleString('es-ES', { month: 'long' }); // Month name in Spanish
        const year = past.getUTCFullYear();
        return PUBLISHED_ON_DAY_MONTH_YEAR(day, month, year);
    } else if (diffDays > 0) {
        return  PUBLISHED_DAYS_AGO(diffDays, diffHours);
    } else {
        return PUBLISHED_HOURS_AGO(diffHours);
    }
}

export function formatPhoneNumber(phoneNumber: string) {
    const cleaned = phoneNumber.replace(/\D/g, '').slice(-9);
    return `+34 ${cleaned.slice(0,3)} ${cleaned.slice(3,6)} ${cleaned.slice(6)}`;
}