export const OFFER_LIST_TITLE = "Ofertas publicadas";
export const EMPTY_OFFER_STATE_TITLE = 'No hay ofertas publicadas';
export const OFFER_CANDIDATES = 'Candidatos';
export const OFFER_DETAILS = 'Detalles oferta';

export const EMPTY_OFFER_CLAIM_TITLE = 'No hay candidatos';

export const OFFER_STATUS_PUBLISHED = 'Activa';
export const OFFER_STATUS_CLOSED = 'Cerrada';

export const OFFER_DURATION = 'Duración';
export const OFFER_COMPENSATION = 'Salario';
export const OFFER_PERKS = 'Beneficios';
export const OFFER_REQUIREMENTS = 'Requisitos';
export const OFFER_DESCRIPTION = 'Descripción';
export const OFFER_ID = 'Oferta N°';

export const CALL_TO_DISCLOSE_DETAILS = 'Haz clic en ‘Mostrar detalles’ para revelar su número de teléfono y correo electrónico. El profesional avanzará al siguiente paso del proceso de contratación'

export const REVIEW_DETAILS_MODAL_TITLE = '¿Quieres contactar a {{professionalName}}?'
export const REVIEW_DETAILS_MODAL_DESCRIPTION = 'Te mostraremos sus detalles de contacto y, por tanto, avanzará automáticamente en el proceso'
export const REVIEW_DETAILS_MODAL_CANCEL_BUTTON = 'Cancelar'
export const REVIEW_DETAILS_MODAL_ACCEPT_BUTTON = 'Sí, mostrar detalles'

export const HIRE_OFFER_CLAIM_MODAL_TITLE = '¿Marcar como contratado?'
export const HIRE_OFFER_CLAIM_MODAL_DESCRIPTION = 'Enhorabuena: nos alegra saber que Alberto encaja para el puesto. Por favor, confírmanos que es así, ya que luego no podrás cambiar esta acción'
export const HIRE_OFFER_CLAIM_MODAL_CANCEL_BUTTON = 'Cancelar'
export const HIRE_OFFER_CLAIM_MODAL_ACCEPT_BUTTON = 'Sí, marcar'

export const REJECT_OFFER_CLAIM_MODAL_TITLE = '¿Quieres descartar a {{professionalName}}?'
export const REJECT_OFFER_CLAIM_MODAL_DESCRIPTION = 'Por favor selecciona un motivo'
export const REJECT_OFFER_CLAIM_MODAL_CANCEL_BUTTON = 'Cancelar'
export const REJECT_OFFER_CLAIM_MODAL_ACCEPT_BUTTON = 'Descartar'
