import { Category, ShiftTimeInDayEnum, SpecializationDTO } from "types/shifts"
import { fetchPublishShiftConfiguration, ShiftPublicationRequest } from "services/publish-shift"
import { PublishShiftConfigurationDTO, ShiftTimeConfigDTO } from "types/publish-shift"
import { CircularProgress } from "@mui/material"
import { BaseShiftForm, ShiftConfiguration } from "./BaseShiftForm"
import { ModalContainer } from "components/common/ModalContainer"
import { HeaderComponent } from "./HeaderComponent"
import { EDIT_SHIFT_BUTTON, EDIT_SHIFT_TITLE, SELECT_CATEGORY_TITLE } from "locales/editShift"
import { PUBLISH_SHIFT_TITLE } from "locales/publishShift"
import { useEffect, useState } from "react"
import { SelectCategory } from "./SelectCategory"
import { useSelector } from "react-redux"
import { RootState } from "store/types"
import React from "react"
import moment from "moment"
import { SHIFT_TIME_IN_DAY_DEFINITIONS } from "utils"
import { ShiftModalityEnum } from "types"

interface PublishEditModalProps {
    onSubmit: (shiftPublishRequest: ShiftPublicationRequest) => Promise<void>;
    shiftDetails: ShiftConfiguration | null;
    onClose: () => void;
    isOpen: boolean;
    isEditing?: boolean;
    selectedDate?: string;
    shiftTimeInDay?: ShiftTimeInDayEnum;
}



const PublishEditModal: React.FC<PublishEditModalProps> = ({
    onSubmit,
    shiftDetails,
    onClose,
    isOpen,
    isEditing,
    selectedDate,
    shiftTimeInDay
}) => {
    const [isChoosingCategory, setIsChoosingCategory] = useState(true)
    const [selectedCategory, setSelectedCategory] = useState<Category | null>(null)
    const facilityProfile = useSelector((state: RootState) => state.account.accountInfo)
    const canChooseCategory =  !isEditing && facilityProfile?.facility.categories && facilityProfile?.facility.categories.length > 1
    const [publishShiftConfiguration, setPublishShiftConfiguration] = useState<PublishShiftConfigurationDTO | null>(null)
    const [loading, setLoading] = useState(false)

    const getTimeForConfig = (config: ShiftTimeConfigDTO, _shiftTimeInDay: ShiftTimeInDayEnum) => {
        const shiftTimeInDayHashKey = SHIFT_TIME_IN_DAY_DEFINITIONS[_shiftTimeInDay].hashKey
        const shiftTimeInDayConfig = config[shiftTimeInDayHashKey as keyof typeof config]
        return [
            moment(selectedDate).set(
                {
                    hour: shiftTimeInDayConfig.startTime.hour,
                    minute: shiftTimeInDayConfig.startTime.minute
                }
            ).toISOString(),
            moment(selectedDate).set(
                {
                    hour: shiftTimeInDayConfig.endTime.hour,
                    minute: shiftTimeInDayConfig.endTime.minute
                }).toISOString()
        ]
    }

    
    const [initialStartTime, initialEndTime] =  publishShiftConfiguration && shiftTimeInDay ? getTimeForConfig(publishShiftConfiguration.shiftTimeConfig, shiftTimeInDay) : [moment(selectedDate).toISOString(), moment(selectedDate).toISOString()]
    const initialCompensationOptions = publishShiftConfiguration?.compensationOptions?.options.filter(option => option.enabledByDefault).map(option => option.value) || []

    const emptyShiftConfig = {
        startTime: initialStartTime,
        finishTime: initialEndTime,
        shiftTimeInDay: shiftTimeInDay || ShiftTimeInDayEnum.MORNING,
        totalPay: '',
        capacity: 1,
        visibility: [ShiftModalityEnum.EXTERNAL],
        specialization: null,
        unit: '',
        details: '',
        externalVisible: true,
        internalVisible: false,
        recurrentDates: [moment(selectedDate).format('YYYY-MM-DD')],
        category: selectedCategory || null,
        unitVisible: true,
        compensationOptions: initialCompensationOptions,
    }

    const initialShiftConfig = shiftDetails || emptyShiftConfig

    const loadConfig = () => {
        setLoading(true)
        fetchPublishShiftConfiguration(selectedCategory?.code).then((response) => {
            setPublishShiftConfiguration(response)
            setLoading(false)
        }).catch((error) => {
            console.error(error)
            setLoading(false)
        })
    }

    const handleClose = () => {
        setIsChoosingCategory(true)
        setSelectedCategory(null)
        onClose()
    }

    useEffect(() => {
       if (initialShiftConfig?.category) {
              setSelectedCategory(initialShiftConfig.category)
              setIsChoosingCategory(false)
       } else {
              setIsChoosingCategory(true)
       }
    }, [initialShiftConfig?.category])

    useEffect(() => {
        if ((!isChoosingCategory || !canChooseCategory) && isOpen) {
            loadConfig()
        }
    }, [isChoosingCategory, isOpen])


    return (
        <ModalContainer
            isOpen={isOpen}
            style={{ width: '60%', maxWidth: '700px' }}
            onClose={handleClose}
        >

            <div
                className="flex flex-col rounded-[8px] overflow-y-auto bg-white rounded-[16px] mx-auto"
            >
                {loading ?
                    <div>
                        <HeaderComponent
                            title={isEditing ? EDIT_SHIFT_TITLE : PUBLISH_SHIFT_TITLE}
                            onClose={handleClose}
                        />
                        <div
                            className=" h-[600px] w-[700px] flex items-center justify-center"
                        >
                            <CircularProgress />
                        </div>
                    </div>
                    : canChooseCategory && isChoosingCategory ?
                        <div>
                            <HeaderComponent
                                title={SELECT_CATEGORY_TITLE}
                                onClose={handleClose}
                            />
                            <div
                                className="w-[700px] flex  justify-start"
                            >
                                <SelectCategory
                                    categories={facilityProfile?.facility.categories || []}
                                    onSelectingCategory={(category) => {
                                        setSelectedCategory(category)
                                        setIsChoosingCategory(false)
                                    }}/>
                            </div>
                        </div> :

                        publishShiftConfiguration ?
                        <BaseShiftForm
                            initialConfig={{
                                ...initialShiftConfig,
                                category: selectedCategory || initialShiftConfig.category,
                            }}
                            publishShiftConfiguration={publishShiftConfiguration}
                            onSubmit={onSubmit}
                            onClose={handleClose}
                            isEditing={isEditing}
                            title={isEditing ? EDIT_SHIFT_TITLE : PUBLISH_SHIFT_TITLE}
                            goBack={canChooseCategory ? () =>  setIsChoosingCategory(true) : undefined}
                        /> : null}

            </div>

        </ModalContainer>

    )
}

export default React.memo(PublishEditModal)