import { RECURRENCY_OPTIONS_ALL_SHIFTS_DISCLAIMER, RECURRENCY_OPTION_ALL_SHIFTS, RECURRENCY_OPTION_THIS_SHIFT } from "locales/editShift"
import { SpecializationDTO } from "./shifts"
import { ShiftSummary } from "services/shifts-calendar"

interface TimeDTO {
  hour: number,
  minute: number,
  second?: number,
  nano?: number
}

interface ScheduleDTO {
  startTime: TimeDTO,
  endTime: TimeDTO
}

export interface ShiftTimeConfigDTO {
  dayShift: ScheduleDTO,
  eveningShift: ScheduleDTO,
  nightShift: ScheduleDTO
}

export interface CalendarSummary {
  date: string,
  holiday: boolean,
}

export type CompensationOptionsConfigType = {
  configurable: boolean,
  options: [
    {
      value: string,
      displayText: string,
      enabledByDefault: boolean
    }
  ]
}
export interface PublishShiftConfigurationDTO {
  specializations: SpecializationDTO[],
  shiftTimeConfig: ShiftTimeConfigDTO,
  livoPoolOnboarded: boolean,
  livoInternalOnboarded: boolean,
  units: string[],
  calendarSummary?: CalendarSummary[]
  unitVisibleConfigurable: boolean,
  compensationOptions?: CompensationOptionsConfigType,
  unitConfigurable: boolean

}

export enum RecurrencyOptionsEnum {
  ALL_SHIFTS = 'ALL_SHIFTS',
  THIS_SHIFT = 'THIS_SHIFT',
}

export const recurrencyOptions: {
  label: string,
  name: RecurrencyOptionsEnum,
  disclaimer?: string
}[] = [
    {
      label: RECURRENCY_OPTION_THIS_SHIFT,
      name: RecurrencyOptionsEnum.THIS_SHIFT,
    },
    {
      label: RECURRENCY_OPTION_ALL_SHIFTS,
      name: RecurrencyOptionsEnum.ALL_SHIFTS,
      disclaimer: RECURRENCY_OPTIONS_ALL_SHIFTS_DISCLAIMER
    },
  ]