import { ClaimRequest, CVType } from "types/claims"
import LivoIcon from "assets/LivoLogo";
import { DOWNLOAD_CV_LABEL, EXPERIENCE_LABEL, LICECNSE_NUMBER_LABEL, PROFESSIONAL_DATA_TITLE, VIEW_LIVO_CV_LABEL } from "locales/professionalClaim";

interface ProfessionalDataBodyProps {
    claim: ClaimRequest,
    onViewLivoCV: () => void
}
export const ProfessionalDataBody: React.FC<ProfessionalDataBodyProps> = ({
    claim,
    onViewLivoCV
}) => {
    const hasLivoCV = claim.professionalProfile.availableCVTypes?.includes(CVType.LIVO_CV);
    const hasPdfUpload = claim.professionalProfile.availableCVTypes?.includes(CVType.PDF_UPLOAD);

    return (

        <div
            className="flex flex-col w-full p-medium"
        >
            <div
                className="flex flex-row space-x-small mb-large"
            >
                <LivoIcon name='id-badge-2' size={24} color='#ACBBCB' />
                <p
                    className="body-regular"
                >
                    {PROFESSIONAL_DATA_TITLE}
                </p>
            </div>
            <div
                className="flex flex-row flex-wrap space-x-small mb-small"
            >
                <p
                    className="body-regular text-[#707A91]"
                >
                    {LICECNSE_NUMBER_LABEL}:
                </p>
                <p
                    className="mr-8 subtitle-regular text-Text-Default"
                >
                    {claim.professionalProfile.licenseNumber}
                </p>
            </div>
            <div className="space-x-small">
                <span className="subtitle-regular text-[#707A91]">
                    {EXPERIENCE_LABEL}:
                </span>
                {hasLivoCV && (
                    <button 
                        className="subtitle-regular body-regular text-[#149EF2] cursor-pointer hover:underline"
                        onClick={() => onViewLivoCV()}
                    >
                        {VIEW_LIVO_CV_LABEL}
                    </button>
                )}
                {hasLivoCV && hasPdfUpload && (
                    <span className="text-[#707A91] mx-8">|</span>
                )}
                <a className="subtitle-regular text-[#149EF2] hover:underline" href={claim.professionalProfile.professionalCV} target="_blank">
                    {DOWNLOAD_CV_LABEL}
                </a>
            </div>
        </div>

    )
}