import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ApiApplicationError } from "services/api"
import { fetchOfferDetail, fetchOffers, mockFetchOfferDetail, mockFetchOffers } from "services/offers"
import { showToastAction, toggleInternetConnection } from "store/actions/appConfigurationActions"
import { RootState } from "store/types"
import { Offer, OfferDetail } from "types/offers"
import { OFFER_STATUS_FILTER_SELECTIONS, OfferListingComponent } from "components/offerlist/OfferListComponent"
import { OfferDetailSection } from "components/offerDetails/OfferDetailSection"
import { useLocation, useNavigate } from "react-router-dom"
import { setOfferListSelectedOfferId } from "store/actions/offers"

export const OfferList: React.FC = () => {
    const [loadingOffers, setLoadingOffers] = useState(true)
    const [offers, setOffers] = useState<Offer[]>([])
    
    const [selectedOfferDetail, setSelectedOfferDetail] = useState<OfferDetail | null>(null)
    const [loadingOfferDetail, setLoadingOfferDetail] = useState(true)

    const { appliedFilter, selectedOfferId } = useSelector((state: RootState) => state.offerList)
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const fetchOffer = async () => {
        if (selectedOfferId) {
            return
        }
        const filterConfiguration = OFFER_STATUS_FILTER_SELECTIONS.find((filter) => filter.id === appliedFilter)
        return fetchOffers(
            filterConfiguration?.mappingStatus
        ).then((response) => {
            setOffers(response.rows)
        }).catch((error) => {
            if (error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
        })
    }

    const getOfferDetails = async () => {
        if (!selectedOfferId) {
            return
        }
        return fetchOfferDetail(selectedOfferId).then((response) => {
            setSelectedOfferDetail(response)
        }).catch((error) => {
            if (error instanceof ApiApplicationError) {
                if (error.cause === 'NO_INTERNET') {
                    dispatch(toggleInternetConnection(false))
                } else {
                    dispatch(showToastAction({
                        message: error.message,
                        severity: 'error'
                    }))
                }
            }
        })
    }
    

    const loadOffers = async () => {
        setLoadingOffers(true)
        await fetchOffer().then(() => {
            setLoadingOffers(false)
        }).catch((error) => {
            console.error(error)
        })
    }

    const loadOfferDetail = async () => {
        setLoadingOfferDetail(true)
        await getOfferDetails().then(() => {
            setLoadingOfferDetail(false)
        }).catch((error) => {
            console.error(error)
        })
    }


    useEffect(() => {
        loadOffers()
        loadOfferDetail()
    }, [appliedFilter, selectedOfferId])

    const setSelectedOfferId = (offerId: number | null) => {
        if (offerId) {
            const newUrl = `${location.pathname}?offerId=${offerId}`
            const currentUrl = location.pathname + location.search
            if (currentUrl !== newUrl) {
                navigate(newUrl)
            }
        } else {
            const newUrl = `${location.pathname}`
            const currentUrl = location.pathname + location.search
            if (currentUrl !== newUrl) {
                navigate(newUrl)
            }
        }
        
        dispatch(setOfferListSelectedOfferId(offerId))
    }

    return (
        <div className="flex h-full space-x-medium justify-between">
            {selectedOfferId ? (
                <OfferDetailSection 
                    offer={selectedOfferDetail}
                    loadingOffer={loadingOfferDetail}
                    selectedOfferId={selectedOfferId} 
                    goBack = {() => setSelectedOfferId(null)}
                />
            ) : (
                <OfferListingComponent
                    offers={offers}
                    loading={loadingOffers}
                    selectOffer={setSelectedOfferId}
                />
            )}
        </div>

    )
}