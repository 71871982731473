import { ActionComponentIdEnum, DayShift, Shift, ShiftTimeInDayEnum } from "types/shifts";
import { DayShiftComponent } from "./DayShiftComponent";
import { CircularProgress } from "@mui/material";
import { ActionButton } from "components/common/ActionButton";
import LivoIcon from "assets/LivoLogo";
import { useState } from "react";
import { PublishShiftModal } from "components/publishShift/PublishShiftModal";
import moment from "moment";
import { SHIFT_LIST_TITLE } from "locales/calendar";
import { PUBLISH_SHIFT_BUTTON } from "locales/publishShift";
import { FiltersRow } from "./FiltersRow";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { setAppliedFilter } from "store/actions/publishShiftListActions";
import { EmptyShiftsState } from "./EmptyShiftsState";
import { FilteredShiftsEmptyState } from "./FilteredShiftsEmptyState";
import { EditShiftModal } from "components/shiftDetails/EditShiftModal";
import { ShiftConfiguration } from "components/publishShift/BaseShiftForm";
import FilterShiftsModal from "./FilterShiftsModal";
import { applyFilter } from "types/common/shiftFilters";

interface ShiftListComponentProps {
    dayShifts: DayShift[];
    loading: boolean;
    reloadData: () => void;
    shadowReload: () => void;
}

export const ShiftListComponent: React.FC<ShiftListComponentProps> = ({
    dayShifts,
    loading,
    reloadData,
    shadowReload
}) => {
    const { appliedFilter } = useSelector((state: RootState) => state.publishShiftList)

    const [publishShiftOpen, setPublishShiftOpen] = useState(false)
    const [actionableShift, setActionableShift] = useState<Shift | null>(null)

    const [editShiftDetailsModalOpen, setEditShiftDetailsModalOpen] = useState(false);
    const [filterModalOpen, setFilterModalOpen] = useState(false);

    const filters = useSelector((state: RootState) => state.filterShifts.filters);
    const filteredDayShifts = [] as DayShift[];
    dayShifts.forEach((dayShift) => {
        let filteredShifts = dayShift.shifts;
        filters.forEach((f) => filteredShifts = applyFilter(f, filteredShifts));
        if(filteredShifts.length > 0) {
            filteredDayShifts.push({
                ...dayShift,
                shifts: filteredShifts,
            });
        }
    });

    const dispatch = useDispatch();

    return (
        <div
            className="flex flex-1 pt-[24px] overflow-y-auto no-scrollbar justify-center w-full" 
        >
            <div
                className="flex flex-col w-full h-full px-medium  pb-[24px] max-w-[1024px]"
            >
                <div
                    className="flex w-full justify-between items-center mb-large flex-wrap space-x-small"

                >
                    <div
                        className="flex space-x-small items-center mr-small"
                    >
                        <p
                            className="heading-md text-ellipsis text-nowrap"
                        >
                            {SHIFT_LIST_TITLE}
                        </p>
                        <p
                            className="body-large text-Text-Subtle "
                        >
                            ({filteredDayShifts.length})
                        </p>
                    </div>
                    <div
                        className="flex self-end"
                    >
                        <ActionButton
                            isDisabled={false}
                            isLoading={false}
                            onClick={() => {
                                setPublishShiftOpen(true)
                                setEditShiftDetailsModalOpen(false)
                            }}
                            inverse={true}
                            color={'#149EF2'}
                        >
                            <div
                                className="flex items-center space-x-small"
                            >
                                <p
                                    className="action-regular text-Action-Primary"
                                >
                                    {PUBLISH_SHIFT_BUTTON}
                                </p>
                                <LivoIcon name='plus' size={24} color='#149EF2' />
                            </div>

                        </ActionButton>
                    </div>

                </div>
                <div className="flex space-x-tiny w-full items-center pb-large flex-wrap space-y-tiny">
                    <FiltersRow
                        appliedFilter={appliedFilter}
                        setFilter={(filter: string) => {
                            console.log('setAppliedFilter', filter)
                            dispatch(setAppliedFilter(filter))
                        }}
                        onFiltersButtonClick={() => setFilterModalOpen(true)}
                    />
                </div>
                <div
                    className="flex w-full space-y-small justify-center"
                >
                    {loading ? (
                        <div
                            className="flex flex-1 justify-center items-center h-full"
                        >
                            <CircularProgress />
                        </div>
                    ) :
                        filteredDayShifts.length === 0 ?

                            appliedFilter === 'all' ?
                                <EmptyShiftsState
                                    onClick={() => {
                                        setPublishShiftOpen(true)
                                    }}
                                /> : <FilteredShiftsEmptyState
                                    onClick={() => {
                                        dispatch(setAppliedFilter('all'))
                                    }}
                                /> :
                            <div
                                className="flex flex-col flex-1 space-y-small"
                            >
                                {filteredDayShifts.map((dayShift, index) => {
                                    return <DayShiftComponent
                                        key={index}
                                        dayShift={dayShift}
                                        actionComponents={[
                                            {
                                                id: ActionComponentIdEnum.EDIT,
                                                iconName: 'pencil',
                                                onClick: (shift: Shift) => {
                                                    setActionableShift(shift)
                                                    setEditShiftDetailsModalOpen(true)
                                                    setPublishShiftOpen(false)
                                                }
                                            },
                                            {
                                                id: ActionComponentIdEnum.COPY,
                                                iconName: 'copy',
                                                onClick: (shift: Shift) => {
                                                    setActionableShift(shift)
                                                    setPublishShiftOpen(true)
                                                    setEditShiftDetailsModalOpen(false)
                                                }
                                            }

                                        ]}
                                    />
                                })}
                                <div
                                    style={{
                                        height: '24px',
                                    }}
                                >
                                </div>
                            </div>

                    }
                </div>

                <PublishShiftModal
                    isOpen={publishShiftOpen}
                    onClose={() => {
                        setPublishShiftOpen(false)
                        setActionableShift(null)
                    }}
                    selectedDate={moment().format('YYYY-MM-DD')}
                    shiftTimeInDay={ShiftTimeInDayEnum.MORNING}
                    reloadData={reloadData}
                    shiftDetails={ actionableShift ? {
                        ...actionableShift,
                        recurrentDates: actionableShift ? [moment(actionableShift.startTime).format('YYYY-MM-DD')] : [],
                        compensationOptions: actionableShift?.compensationOptions.map((option) => option.value) || []
                    } as ShiftConfiguration : undefined}
                />
                <EditShiftModal
                    isOpen={editShiftDetailsModalOpen && actionableShift !== null}
                    onClose={() => {
                        setEditShiftDetailsModalOpen(false)
                        setActionableShift(null)
                    }}
                    reloadData={() => {
                        shadowReload()
                    }}
                    shiftDetails={actionableShift}
                />
            </div>
            <FilterShiftsModal
                isOpen={filterModalOpen}
                onClose={() => setFilterModalOpen(false)}
            />
        </div>
    )
}