import { CircularProgress } from "@mui/material";
import { CustomInput } from "components/common/CustomInput";
import { ModalContainer } from "components/common/ModalContainer";
import { SingleSelectItem } from "components/common/SingleSelectItem";
import { REJECT_OFFER_CLAIM_MODAL_TITLE, REJECT_OFFER_CLAIM_MODAL_CANCEL_BUTTON, REJECT_OFFER_CLAIM_MODAL_ACCEPT_BUTTON, REJECT_OFFER_CLAIM_MODAL_DESCRIPTION } from "locales/offers";
import { useState } from "react";
import { useEffect } from "react";
import { fetchOfferClaimRejectReasons } from "services/offers";
import { OfferClaimRejectReason } from "types/offers";


interface RejectOfferClaimModalProps {
    professionalName: string;
    isOpen: boolean;
    rejectOfferClaim: (reason: string, details: string) => void;
    onClose: () => void;
}

export const RejectOfferClaimModal: React.FC<RejectOfferClaimModalProps> = ({
    professionalName,
    isOpen,
    rejectOfferClaim,
    onClose
}) => {

    const [reasons, setReasons] = useState<OfferClaimRejectReason[]>([])
    const [loading, setLoading] = useState(true)
    const [selectedReason, setSelectedReason] = useState<string | null>(null)
    const [details, setDetails] = useState('')

    const validSelectedReason = selectedReason !== null && (selectedReason !== 'OTHER' || details.length > 0);

    useEffect(() => {
        if (isOpen) {
            setLoading(true)
            fetchOfferClaimRejectReasons().then((response) => {
                setReasons(response)
                setLoading(false)
            })
        }
    }, [isOpen])


    const handleRejectOfferClaim = () => {
        if (validSelectedReason) {
            rejectOfferClaim(selectedReason!!, details)
        }
    }

    return (
        <ModalContainer isOpen={isOpen} onClose={onClose}>
            <div className="rounded-[16px] shadow-custom w-[410px] bg-white">
                <div className="px-6 pt-6 pb-3">
                    <h3 className="heading-md mb-4">{REJECT_OFFER_CLAIM_MODAL_TITLE.replace('{{professionalName}}', professionalName)}</h3>
                    <p className="body-md mb-4">{REJECT_OFFER_CLAIM_MODAL_DESCRIPTION}</p>
                    {!loading ?
                            reasons.map((reason) => (
                                <div
                                    className="flex flex-col w-full py-small"
                                >
                                    <SingleSelectItem
                                        option={
                                            reason.displayText
                                        }
                                        onClick={() => setSelectedReason(reason.value)}
                                        checked={selectedReason === reason.value}
                                    />
                                    {
                                        selectedReason === 'OTHER' && reason.value === 'OTHER' &&
                                        <div
                                            className="pt-small"
                                        >
                                            <CustomInput
                                                placeHolder="Detalles"
                                                selectedValue={details}
                                                setValue={setDetails}
                                            ></CustomInput>
                                        </div>
                                    }
                                </div>
                            )) :
                            <div className="flex flex-col w-full p-small items-center justify-center">
                                <CircularProgress />
                            </div>
                        }
                </div>


                <div className="px-3 pt-6 pb-4">
                    <div className="flex flex-row items-center"> {/* Changed margin-top to add space */}
                        <button
                            onClick={onClose}
                            className="flex flex-1 text-center text-Action-Primary items-center justify-center py-large px-small"
                        >
                            <p className="action-regular w-full">
                                {REJECT_OFFER_CLAIM_MODAL_CANCEL_BUTTON}
                            </p>
                        </button>

                        <button
                            className={`flex flex-1 py-large px-small rounded-[100px] text-center ${validSelectedReason ? "bg-Negative-400 text-Text-Inverse" : "bg-Background-Secondary text-Text-Subtle"}`}
                            disabled={!validSelectedReason}
                            onClick={() => {
                                handleRejectOfferClaim()
                            }}
                        >
                            <p className="w-full action-regular">
                                {REJECT_OFFER_CLAIM_MODAL_ACCEPT_BUTTON}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}