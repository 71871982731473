
export const setAppliedFilter = (filter: string) => {
    return {
        type: 'SET_OFFER_FILTER',
        payload: filter
    }
}

export const setOfferListSelectedOfferId = (offerId: number | null ) => {
    return {
        type: 'SET_OFFER_LIST_SELECTED_OFFER_ID',
        payload: offerId
    }
}