import moment from 'moment';
import { ShiftTimeInDayEnum } from 'types/shifts';

export function formatDateToYYYYMMDD(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function isToday(date: string): boolean {
  return moment(date).isSame(moment.now(), 'day');
}

function getTomorrowOf(date: Date): Date {
  return moment(date).add(1, 'days').toDate();
}

function isTomorrow(date: string): boolean {
  let tomorrow = getTomorrowOf(new Date());
  return moment(date).isSame(tomorrow, 'day');
}

export function formatDate(date: string) {
  let parsedDate = moment(date).toDate();

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
  };
  let formattedDate = parsedDate.toLocaleDateString('es-ES', options);
  formattedDate =
    formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);

  return formattedDate;
}

export function formatDateWithToday(parsedDate: string) {
  // compare 2 dates in typescript
  if (isToday(parsedDate)) {
    return 'Hoy';
  }

  if (isTomorrow(parsedDate)) {
    return 'Mañana';
  }


  return formatDate(parsedDate);
}

export function formatTime(date: string) {
  const timeDate = new Date(date)
  return timeDate.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  });
}

export function formatSchedule(startTime: string, finishTime: string) {
  const formattedStartTime = formatTime(startTime)
  const formattedEndTime = formatTime(finishTime)

  return `${formattedStartTime} - ${formattedEndTime}`;
}

export function getMonthName(date: string) {
  const month = moment(date).month();
  return `${timeConfiguration.monthNames[month]} ${moment(date).year()}`;
}

interface ShiftTimeDefinition {
  id: string;
  name: string;
  color: string;
  icon: string;
  hashKey: string;
}
export const SHIFT_TIME_IN_DAY_DEFINITIONS: {
  [key in ShiftTimeInDayEnum]: ShiftTimeDefinition;
} = {
  [ShiftTimeInDayEnum.MORNING]: {
    id: 'DAY_SHIFT',
    name: 'Mañana',
    color: '#ff911c',
    icon: 'sunrise',
    hashKey: 'dayShift',
  },
  [ShiftTimeInDayEnum.EVENING]: {
    id: 'EVENING_SHIFT',
    name: 'Tarde',
    color: '#fe69b5',
    icon: 'sunset',
    hashKey: 'eveningShift',
  },
  [ShiftTimeInDayEnum.NIGHT]: {
    id: 'NIGHT_SHIFT',
    name: 'Noche',
    color: '#138da6',
    icon: 'moon',
    hashKey: 'nightShift',
  },
}

export const timeConfiguration = {
  monthNames: [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ],
  monthNamesShort: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
  dayNames: ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'],
  dayNamesShort: ['Lun', 'Mar', 'Mie', 'Jue', 'Vie', 'Sab', 'Dom'],
  today: "Hoy"
};
