import LivoIcon from "assets/LivoLogo"
import { NotificationsBadge } from "components/common/NotificationsBadge"

interface OfferSideBarMenuProps {
    label: string,
    badge: number,
    onClick: () => void,
    selected: boolean,
}

export const OfferSideBarMenu: React.FC<OfferSideBarMenuProps> = ({ 
    label, 
    badge, 
    onClick, 
    selected 
}) => {
    return (
        <div className={`${selected ? 'bg-[#375d68] text-white' : 'text-[#2c3038]'} self-stretch pl-2 pr-1 py-3 rounded-lg justify-start items-center gap-2 inline-flex `} onClick={onClick}>
            <div className="grow shrink basis-0 text-base font-normal font-['Roboto'] leading-normal">
                {label}
            </div>
            {badge > 0 && <NotificationsBadge notifications={badge} />}
            <LivoIcon name='chevron-right' size={24} color='#ACBBCB' />
        </div>
    )
}
