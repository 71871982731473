import LivoIcon from "assets/LivoLogo";
import { NO_REVIEWS_LABEL } from "locales/shiftClaimDetails/es";
import { ProfessionalReviewInfo } from "types/claims";

interface ProfessionalRatingStarProps {
    professionalReview: ProfessionalReviewInfo;
    textClassName?: string;
}

export const ProfessionalRatingStar: React.FC<ProfessionalRatingStarProps> = ({ professionalReview, textClassName }) => {
    return (
        professionalReview.totalReviews > 0 ?
        <div
            className="flex flex-row items-center space-x-tiny w-full justify-start :hovered:bg-Background-Secondary"
        >
            <LivoIcon name='star-filled' size={12} color='#FF911C' />
            <p className={`body-sm ${textClassName ? textClassName : 'text-Text-Default'}`}>{professionalReview.averageRating}</p>

            <p className={`body-sm ${textClassName ? textClassName : 'text-Text-Subtle'}`}>
                {`(${professionalReview.totalReviews} ${professionalReview.totalReviews > 1 ? 'Reseñas' : 'Reseña'})`}
            </p>
        </div>
        :
        <p className={`info-caption ${textClassName ? textClassName : 'text-Text-Subtle'}`}>
            {NO_REVIEWS_LABEL}
        </p>
    );
}
