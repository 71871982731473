import { ShiftModalityEnum } from "types";
import { DataFieldSubmission, DataFieldType, DenarioProfessional, FacilityDataFieldDefinition, InternalProfessional, internalProfessionalDisplayName } from "types/internal";
import { EditCategoryComponent } from "./EditCategoryComponent";
import { Category } from "types/shifts";
import { ProfilePicture } from "components/shiftDetails/ProfilePicture";
import { RootState } from "store/types";
import { useSelector } from "react-redux";
import LivoIcon from "assets/LivoLogo";
import { MultiSelectDataField } from "./MultiSelectDataField";
import { CategoryTag } from "components/common/CategoryTag";
import { CATEGORY_SECTION_LABEL, SKILLS_LABEL, SKILLS_SECTION_LABEL, PERSONAL_DATA_LABEL, PHONE_LABEL, MISSING_EMAIL_LABEL } from "locales/professionals/profile";
import { Disclaimer } from "components/common/InfoBanner";
import { FacilityDataField } from "./FacilityDataField";
import { useState } from "react";
import { getDenarioProfessional } from "services/internal";
import { DenarioProfessionalModal } from "./DenarioProfessionalModa";
import { formatPhoneNumber } from "utils/utils";
import { AccountInfo } from "services/account";
import { isDenarioIntegrated } from "./common";
import { EMAIL_LABEL } from "locales/professionals/profile";


interface EditProfessionalCardHeaderProps {
    professionalProfile: InternalProfessional
    modality: ShiftModalityEnum | null;
    selectedCategory: Category,
    availableCategories: Category[],
    setSelectedCategory: (category: Category) => void,
    setSelectedSkills: (skills: string[]) => void,
    selectedSkills: string[],
    personalDataFieldDefinitions: FacilityDataFieldDefinition[],
    dataFieldSubmissions: DataFieldSubmission[],
    setDataFieldSubmissions: (dataFieldSubmissions: DataFieldSubmission[]) => void,
}

export const EditProfessionalCardHeader: React.FC<EditProfessionalCardHeaderProps> = ({
    professionalProfile,
    modality,
    selectedCategory,
    availableCategories,
    setSelectedCategory,
    setSelectedSkills,
    selectedSkills,
    personalDataFieldDefinitions,
    dataFieldSubmissions,
    setDataFieldSubmissions,
}) => {
    const { accountInfo } = useSelector((state: RootState) => state.account)
    const canConfigureCategory = accountInfo?.facility.categories && accountInfo?.facility.categories.length > 1
    const professionalPersonalDataFields = professionalProfile.dataFields.filter((attribute) => personalDataFieldDefinitions.some((dataField) => dataField.key === attribute.key))
    .map((attribute) => ({
        key: attribute.key,
        selectedValues: attribute.values?.length ? attribute.values.map(value => value.value) : [attribute.value],
        editable: attribute.editable
    }))

    function getSkillsByCategory(category: Category) {
        if (category && category.code) {
            return accountInfo?.facility.skillsByCategory[category.code];
        }
        return []
    }

    const [nationalIdErrorMessage, setNationalIdErrorMessage] = useState('')
    const [isLoadingNationalId, setIsLoadingNationalId] = useState(false)
    const [showDenarioModal, setShowDenarioModal] = useState(false)
    const [denarioProfessional, setDenarioProfessional] = useState<DenarioProfessional | null>(null)


    function handleNationalIdChanged(nationalId: string) {
        if (accountInfo?.facility.livoInternalOnboardingStrategy === 'DENARIO_INTEGRATED') {
            setIsLoadingNationalId(true)
            setNationalIdErrorMessage('')
            getDenarioProfessional(nationalId).then((denarioProfessional) => {
                if (typeof denarioProfessional === 'string') {
                    setNationalIdErrorMessage(denarioProfessional)
                } else {
                    setShowDenarioModal(true);
                    setDenarioProfessional(denarioProfessional);
                }
            }).finally(() => {
                setIsLoadingNationalId(false)
            })
        }
    }

    function handleAcceptProfessionalData() {
        setShowDenarioModal(false);
        // set data field submissions for EMPLOYEE_NUMBER with the value from denarioProfessional
        var newSubmissions = updateDataFieldSubmissions(dataFieldSubmissions,'EMPLOYEE_NUMBER', denarioProfessional!!.employeeNumber)
        newSubmissions = updateDataFieldSubmissions(newSubmissions, 'UNIT', denarioProfessional!!.unit)
        console.log("new submissions", newSubmissions)
        setDataFieldSubmissions(newSubmissions)
    }

    function updateDataFieldSubmissions(submissions: DataFieldSubmission[], key: string, value: string) {
        const newDataFieldSubmissions = submissions.filter((attribute) => {
            return attribute.key !== key
        })
        const newDataFieldSubmission = {
            key: key,
            selectedValues: [value],
            editable: false
        }
        newDataFieldSubmissions.push(newDataFieldSubmission)
        return newDataFieldSubmissions
    }

    function updateSelectedCategory(categoryCode: string) {
        setSelectedCategory(availableCategories.find((category) => category.code === categoryCode)!!)
        if (categoryCode !== selectedCategory?.code) {
            setSelectedSkills([]);
        }
    }

    return (

        <div
            className="flex flex-col justify-center items-center w-full space-y-small pb-large"
        >
            <ProfilePicture profilePictureUrl={professionalProfile?.profilePictureUrl} modality={modality} size={64} />
            <div
                className="flex flex-col items-center w-full "
            >
                <p className="heading-md text-Text-Default px-small">
                    {internalProfessionalDisplayName(professionalProfile)}
                </p>
                
                {professionalProfile.disclaimer != null && (
                    <Disclaimer message={professionalProfile.disclaimer.message} severity={professionalProfile.disclaimer.severity} />
                )
                }
                
                {isDenarioIntegrated(accountInfo) && (
                    <div className="flex flex-col w-full space-y-large py-medium" >
                        <div className="flex flex-row space-x-small" >
                            <LivoIcon name='stethoscope' size={24} color='#ACBBCB' />
                            <p className="body-regular" >
                            {PERSONAL_DATA_LABEL}
                        </p>
                    </div>
                    <div className="flex items-center space-x-small">
                        <div className="flex flex-col">
                            <div className="text-[#707a91] text-base font-normal font-['Roboto'] leading-normal pb-1">{PHONE_LABEL}</div>
                            <div className="text-[#2c3038] text-base font-bold font-['Roboto'] leading-normal">{formatPhoneNumber(professionalProfile.phoneNumber)}</div>
                        </div>
                    </div>
                    
                    <div className="flex items-center space-x-small">
                        <div className="flex flex-col">
                            <div className="text-[#707a91] text-base font-normal font-['Roboto'] leading-normal pb-1">{EMAIL_LABEL}</div>
                            <div className="text-[#2c3038] text-base font-bold font-['Roboto'] leading-normal">{professionalProfile.email || MISSING_EMAIL_LABEL}</div>
                        </div>
                    </div>

                    {personalDataFieldDefinitions.map((dataField, index) => {
                        const originalAttribute = professionalPersonalDataFields.find((attribute) => attribute.key === dataField.key)
                        const hasChanged = originalAttribute && originalAttribute.selectedValues.join() !== dataFieldSubmissions.find((attribute) => attribute.key === dataField.key)?.selectedValues.join()
                        return (
                            <FacilityDataField
                                key={index}
                                editable={originalAttribute ? originalAttribute.editable : true}
                                facilityDataFieldDefinition={dataField}
                                dataFieldSubmission={dataFieldSubmissions.find((attribute) => attribute.key === dataField.key)}
                                setDataFieldSubmission={(dataFieldSubmission) => {
                                    const newDataFieldSubmissions = dataFieldSubmissions.filter((attribute) => {
                                        return attribute.key !== dataField.key
                                    })
                                    newDataFieldSubmissions.push(dataFieldSubmission)
                                    setDataFieldSubmissions(newDataFieldSubmissions);
                                }}
                                hasChanged={hasChanged}
                                onChange={(dataFieldSubmission) => {
                                    handleNationalIdChanged(dataFieldSubmission.selectedValues[0])
                                }}
                                isLoading={isLoadingNationalId}
                                errorMessage={nationalIdErrorMessage}
                            />)
                    }
                    )}
                </div>
                )}
                {showDenarioModal && denarioProfessional && <DenarioProfessionalModal 
                    denarioProfessional={denarioProfessional}
                    onClose={() => setShowDenarioModal(false)}
                    onAcceptProfessionalData={handleAcceptProfessionalData}
                />}

                {
                    canConfigureCategory || professionalProfile.skills ?
                        <div
                            className="flex flex-col w-full space-y-large py-medium"
                        >
                            <div
                                className="flex flex-row space-x-small"
                            >
                                <LivoIcon name='stethoscope' size={24} color='#ACBBCB' />
                                <p
                                    className="body-regular"
                                >
                                    {professionalProfile.category ? CATEGORY_SECTION_LABEL : SKILLS_SECTION_LABEL}
                                </p>
                            </div>
                            {canConfigureCategory ?
                                    !professionalProfile.categoryEditable ?
                                    <div
                                        className="flex items-center space-x-small"
                                    >
                                        <CategoryTag text={professionalProfile.category.acronym} />
                                        <p
                                            className="body-regular text-Text-Subtle"
                                        >
                                            {professionalProfile.category.displayText}
                                        </p>
                                    </div>

                                    :
                                    <EditCategoryComponent
                                        hasChanged={selectedCategory?.code !== professionalProfile.category?.code}
                                        selectedCategory={selectedCategory}
                                        availableCategories={availableCategories}
                                        setSelectedCategory={(category) => {
                                            updateSelectedCategory(category.code)
                                        }}
                                    /> : null}
                                
                            {(getSkillsByCategory(selectedCategory) || []).length > 0 ?
                                <MultiSelectDataField
                                    hasChanged={selectedSkills !== professionalProfile.skills.professionalSkills}
                                    title={SKILLS_LABEL}
                                    dataFieldDefinition={{
                                        key: "skills",
                                        label: SKILLS_LABEL,
                                        type: DataFieldType.MULTI_SELECT,
                                        options: getSkillsByCategory(selectedCategory) || [],
                                        editable: true
                                    }}
                                    selectedValues={selectedSkills}
                                    setSelectedValues={(selectedValues) => {
                                        setSelectedSkills(
                                            selectedValues
                                        );
                                    }}
                                /> : null}
                        </div> : null}
            </div>
        </div>
    )
}
