export const EMPTY_SHIFTS_STATE_TITLE = 'No hay turnos programados para este momento del día. Una vez creado un turno podrás visualizarlo aquí.';
export const PAST_EMPTY_SHIFTS_STATE_TITLE = 'No se publicaron turnos para este momento del día.';
export const EMTPY_SHIFTS_STATE_PUBLISH_SHIFT = 'Crear turno';
export const EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE = 'Presiona en ';
export const EMPTY_SHIFTS_STATE_PUBLISH_SHIFT_SUBTITLE_2 = ' para publicar un nuevo turno.';
export const SHIFT_LIST_TITLE = 'Turnos publicados';
export const ACTIVITY_TITLE = 'Actividad';
export const ACTIVITY_EMPTY_STATE_TITLE = 'No hay nada aqui';
export const ACTIVITY_EMPTY_STATE_SUBTITLE = 'Parece has revisado toda tu actividad';
export const ACTIVITY_DETAILS_EMPTY_STATE_TITLE = '¡Estás al día!';
export const ACTIVITY_DETAILS_EMPTY_STATE_SUBTITLE = 'No tienes actividad pendiente. Revisa tus turnos publicados para saber si tienes tareas pendientes.';
export const ACTIVITY_DETAILS_BUTTON = 'Ver turnos publicados';
