import { ModalContainer } from "components/common/ModalContainer";
import { HIRE_OFFER_CLAIM_MODAL_TITLE, HIRE_OFFER_CLAIM_MODAL_DESCRIPTION, HIRE_OFFER_CLAIM_MODAL_CANCEL_BUTTON, HIRE_OFFER_CLAIM_MODAL_ACCEPT_BUTTON } from "locales/offers";
interface HireOfferClaimModalProps {
    isOpen: boolean;
    onAccept: () => void;
    onClose: () => void;
}

export const HireOfferClaimModal: React.FC<HireOfferClaimModalProps> = ({
    isOpen,
    onAccept,
    onClose,
}) => {
    return (
        <ModalContainer isOpen={isOpen} onClose={onClose}>
            <div className="rounded-[16px] shadow-custom w-[410px] bg-white">
                <div className="px-6 pt-6 pb-3">
                    <h3 className="heading-md mb-4">{HIRE_OFFER_CLAIM_MODAL_TITLE}</h3>
                    <div 
                        className="body-regular" 
                        style={{ 
                            alignSelf: 'flex-start', 
                            fontFamily: 'Roboto', 
                            fontSize: '16px', 
                            lineHeight: '20px',
                            color: '#7D7D7D', 
                            marginBottom: '12px' 
                        }}
                    >
                        {HIRE_OFFER_CLAIM_MODAL_DESCRIPTION}
                    </div>
                </div>
                <div className="px-3 pt-6 pb-4">
                    <div className="flex flex-row items-center"> {/* Changed margin-top to add space */}
                        <button
                            onClick={onClose}
                            className="flex flex-1 text-center text-Action-Primary items-center justify-center py-large px-small"
                        >
                            <p className="action-regular w-full">
                                {HIRE_OFFER_CLAIM_MODAL_CANCEL_BUTTON}
                            </p>
                        </button>

                        <button
                            className="flex flex-1 py-large px-small rounded-[100px] text-center bg-[#149EF2] text-Text-Inverse"
                            onClick={onAccept}
                        >
                            <p className="w-full action-regular">
                                {HIRE_OFFER_CLAIM_MODAL_ACCEPT_BUTTON}
                            </p>
                        </button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    );
}