import { CVType, ProfessionalReview, ProfessionalReviewInfo } from "./claims";

export interface Offer {
    id: number;
    category: OfferCategory;
    skills: string[];
    duration: string;
    compensation: OfferCompensation;
    status: OfferStatus;
    totalNewClaims: number;
}

export interface OfferCategory {
    code: string;
    acronym: string;
    displayText: string;
}

export interface OfferCompensation {
    displayText: string;
    defined: boolean;
}

export enum OfferStatus {
    PUBLISHED = 'PUBLISHED',
    CLOSED = 'CLOSED'
}

export interface OfferDetail {
    id: number;
    externalId: string;
    category: OfferCategory;
    status: OfferStatus;
    duration: {
        contractDuration: string;
        contractDates: string;
        summary: string;
    };
    compensation: {
        displayText: string;
        defined: boolean;
    };
    perks: OfferPerk[];
    requirements: {
        skills: OfferSkill[];
        otherRequirements: string;
    };
    description: string;
    totalNewClaims: number;
}

export interface OfferPerk {
    displayText: string;
    icon: string;
}

export interface OfferSkill {
    displayText: string;
    skillRequirement: string;
}

export interface OfferListingResponse {
    total: number;
    rows: Offer[];
}

export interface OfferClaimsResponse {
    total: number;
    totalNewClaims: number;
    rows: OfferClaim[];
}

export interface OfferClaim {
    id: number;
    status: OfferClaimStatus;
    newClaim: boolean;
    professionalProfile: OfferProfessionalProfile;
}

export interface OfferProfessionalProfile {
    id: number;
    modality: string;
    skills: string[];
    fullName: string;
    email: string;
    phoneNumber: string;
    profilePictureUrl: string;
    licenseNumber: string;
    professionalCV: string;
    cvSummary: string;
    professionalReview: ProfessionalReviewInfo,
    totalShiftsInFacility: number;
    favorite: boolean;
    availableCVTypes: CVType[];
}

export enum OfferClaimStatus {
    VISIBLE = 'VISIBLE',
    DETAILS_DISCLOSED = 'DETAILS_DISCLOSED',
    REJECTED = 'REJECTED',
    HIRED = 'HIRED'
}

export interface OfferClaimRejectReason {
    value: string;
    displayText: string;
}