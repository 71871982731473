import { CLEAN_FILTER_LABEL, SEARCH_LABEL } from "locales/shiftClaimList/es";
import DropDownWithInput from "./DropDownWithInput";
import { TagLabel } from "./TagLabel";
import { useDispatch } from "react-redux";
import { clearSelectedOptionsInFilter, selectOptionInFilter, unselectOptionInFilter } from "store/actions/filtersActions";
import { getOptionToDisplayText, Filter, FilterOption } from "types/common/shiftFilters";

interface FilterComponentProps {
  filter: Filter;
  selectOptionInFilter: (filter: Filter, option: FilterOption) => void;
  unselectOptionInFilter: (filter: Filter, option: FilterOption) => void;
  clearSelectedOptionsInFilter: (filter: Filter) => void;
}

export function FilterComponent ({
  filter,
  selectOptionInFilter,
  unselectOptionInFilter,
  clearSelectedOptionsInFilter,
}: FilterComponentProps) {
  const dispatch = useDispatch();

  return (
    <div className="mb-medium">
      <div className="flex flex-row justify-between">
        <h1
          className="subtitle-regular mb-small"
        >
          {filter.name}
        </h1>
        { filter.selectedOptions.length > 0 &&
          <p
              className="text-Primary-500 cursor-pointer"
              onClick={() => clearSelectedOptionsInFilter(filter)}
          >
              {CLEAN_FILTER_LABEL}
          </p>
        }
      </div>
      <div className="flex flex-wrap mb-small">
        {
          filter.selectedOptions.map((option) => (
            <div className="mr-tiny my-[2px]">
              <TagLabel
                text={getOptionToDisplayText(filter)(option)}
                onRemove={() => unselectOptionInFilter(filter, option)}
              />
            </div>
          ))
        }
      </div>
      <div className="mb-medium">
        <DropDownWithInput
          options={filter.options.filter(option => !filter.selectedOptions.includes(option))}
          placeholder={SEARCH_LABEL + " " + filter.name.toLowerCase()}
          storeParentOption={(option) => selectOptionInFilter(filter, option)}
          optionToString={getOptionToDisplayText(filter)}
        />
      </div>
    </div>
  )
}
