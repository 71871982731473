import LivoIcon from "assets/LivoLogo";

interface DisclaimerProps {
    message: string;
    severity: string;
}

export const Disclaimer: React.FC<DisclaimerProps> = ({ message, severity }) => {
    return (    
        <div className="h-6 px-2 pb-2 py-1 bg-[#f4f5f7] rounded justify-start items-center gap-1 inline-flex">
            {severity === 'WARNING' && <LivoIcon name="alert-triangle" size={16} color="#FF0000" />}
            <div className="text-[#2c3038] text-xs font-normal font-['Roboto'] leading-none">
                {message}
            </div>
        </div>
    )
}
