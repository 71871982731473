import { QualificationCard } from "./QualificationCard";
import { LivoCVDetailsDTO } from "types/common/curriculum";
import { ModalWithCloseButtonContainer } from "components/common/ModalWithCloseButton";

interface LivoCVModalProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  livoCVDetails: LivoCVDetailsDTO;
  fullScreen?: boolean;
  style?: any;
}

export function LivoCVModal({
  title,
  isOpen,
  onClose,
  livoCVDetails,
  fullScreen,
  style
}: LivoCVModalProps) {
  return (
    <ModalWithCloseButtonContainer 
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      fullScreen={fullScreen}
      style={style}
    >
      <div className="bg-Grey-050 p-4 h-3/4 overflow-y-scroll">
        <QualificationCard qualification={livoCVDetails.education} />
        <QualificationCard className="mt-3" qualification={livoCVDetails.experience} />
      </div>
    </ModalWithCloseButtonContainer>
  )
}