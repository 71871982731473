import queryString from "query-string"
import { OfferListAction, OfferListState } from "store/types"

const initialState: OfferListState = {
    appliedFilter: 'all',
    selectedOfferId: selectedOfferIdOrNull()
}

const offerListReducer = (state = initialState, action: OfferListAction) => {
    switch (action.type) {
        case 'SET_OFFER_FILTER':
            return {
                ...state,
                appliedFilter: action.payload
            }
        case 'SET_OFFER_LIST_SELECTED_OFFER_ID':
            return {
                ...state,
                selectedOfferId: action.payload
            }
        default:
            return state
    }
}

function selectedOfferIdOrNull() {
    const parsed = queryString.parse(window.location.search);
    return parsed.offerId ? parseInt(parsed.offerId as string) : null;
}

export default offerListReducer;

