import { TagLabel } from "components/common/TagLabel";
import { ProfilePicture } from "components/shiftDetails/ProfilePicture";
import { ShiftModalityEnum } from "types";
import { OfferClaim} from "types/offers";
import { OfferClaimStatusTag } from "./OfferStatusTag";
import { ProfessionalRatingStar } from "components/common/ProfessionalRatingStar";
import { SkillTagsCompact } from "./SkillTagsCompact";

interface OfferClaimCardCompactProps {
    claim: OfferClaim;
    isSelected: boolean;
    selectClaim: (claimId: OfferClaim) => void;
}

export const OfferClaimCardCompact: React.FC<OfferClaimCardCompactProps> = ({
    claim,
    isSelected,
    selectClaim
}) => {

    return (
        <div className="relative">
            <div
                ref={node => {
                    if (node && isSelected) {
                        node.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    }
                }}
                onClick={() => selectClaim(claim)}
                className={`flex flex-row py-small px-small items-center ${isSelected ? 'bg-Action-Secondary text-Text-Inverse' : 'bg-white'} rounded-[16px] relative cursor-pointer`}
                style={{
                    minWidth: '200px',
                    maxWidth: '1280px'
                }}
            >
                <div className="rounded-lg justify-start items-start gap-2 inline-flex">
                    <div className="grow shrink basis-0 justify-start items-start gap-4 flex">
                        <div className="w-12 h-12 relative rounded-lg">
                            <ProfilePicture profilePictureUrl={claim.professionalProfile.profilePictureUrl} size={48} modality={ShiftModalityEnum.EXTERNAL} />
                        </div>
                        <div className="grow shrink basis-0 min-h-20 justify-start items-start gap-2 flex">
                            <div className="grow shrink basis-0 flex-col justify-center items-start gap-2 inline-flex">
                                <div className="w-48 text-base font-normal font-['Roboto'] leading-normal">{claim.professionalProfile.fullName}</div>
                                <div className="w-44 justify-start items-start gap-1 inline-flex">
                                    <SkillTagsCompact skills={claim.professionalProfile.skills} />
                                </div>
                                {claim.professionalProfile.professionalReview && (
                                    <div className="justify-start items-center gap-1 inline-flex">
                                        <ProfessionalRatingStar 
                                            professionalReview={claim.professionalProfile.professionalReview}
                                            textClassName={isSelected ? "text-Text-Inverse" : undefined}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="w-20 self-stretch flex-col justify-start items-end gap-2 inline-flex">
                                <div className="w-20 justify-end items-start gap-2.5 inline-flex">
                                    <OfferClaimStatusTag status={claim.status} />
                                </div>
                            </div>
                            <div className="w-[20px] absolute right-0 top-1/2 transform -translate-y-1/2">
                                {claim.newClaim && (
                                    <div className="flex items-center justify-center">
                                        <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
                                    </div>
                                )}
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}