import LivoIcon from 'assets/LivoLogo';
import React from 'react';
import { createPortal } from 'react-dom';

interface ModalWithCloseButtonContainerProps {
    children: any,
    isOpen: boolean,
    style?: any
    title?: string,
    onClose: () => void,
    fullScreen?: boolean
}

export const ModalWithCloseButtonContainer: React.FC<ModalWithCloseButtonContainerProps> = ({
    children,
    isOpen,
    style,
    title,
    onClose,
    fullScreen
}) => {
    const modalVisibilityClass = isOpen ? 'opacity-100 visible' : 'opacity-0 invisible';
    const backdropVisibilityClass = isOpen ? 'opacity-50 visible' : 'opacity-0 invisible';

    const component = (
        <div className={`flex justify-center fixed z-50 inset-0 overflow-y-auto items-center transition-opacity duration-300 h-screen ${modalVisibilityClass}`}>
            <div className={`fixed inset-0 bg-black transition-opacity duration-300 ${backdropVisibilityClass}`}></div>
            <div className="relative z-10 w-full h-full flex items-center" style={style}>
                <div
                    className="flex flex-col w-full overflow-y-auto bg-white rounded-[16px] mx-auto z-50"
                    style={{ maxHeight: '90%' }}
                >
                    <div
                        className="flex flex-row justify-between items-center p-large border-b border-Divider-Default w-full"
                    >

                        <p
                            className="heading-md"
                        >
                            {title}
                        </p>
                        <button
                            onClick={() => {
                                onClose()
                            }}
                            className="flex items-center justify-center"
                        >
                            <LivoIcon name='close' size={24} color='#707A91' />
                        </button>
                    </div>
                    {children}
                </div>
            </div>
        </div>
    );

    if (fullScreen) {
        return createPortal(component, document.body);
    }

    return component;
}
