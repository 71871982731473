import { ShiftDetailsRow } from "./ShiftDetailsRow"
import { SHIFT_TIME_IN_DAY_DEFINITIONS, formatDate } from "utils";
import { Shift } from "types/shifts";
import { ScheduleComponent } from "components/shifts/ScheduleComponent";
import { ModalityTag } from "components/internal/ModalityTag";
import { ShiftModalityEnum } from "types";
import { CopyButton } from "components/common/CopyButton";
import { IDENTIFIER_COPIED } from "locales/shiftList";
import { TagLabel } from "components/common/TagLabel";
import { CategoryTag } from "components/common/CategoryTag";
import { HolidayTag } from "./HolidayTag";
import { HIDDEN_LABEL } from "locales/internalProfessionalPage";
import { formatTimeSince } from "utils/utils";

interface ShiftDetailsBodyProps {
    shift: Shift;
}

export const ShiftDetailsBody: React.FC<ShiftDetailsBodyProps> = ({
    shift
}) => {
    const shiftTimeInDayProps = SHIFT_TIME_IN_DAY_DEFINITIONS[shift.shiftTimeInDay]
    const poolAndInternalOnboarded = shift.livoPoolOnboarded && shift.livoInternalOnboarded;
    const priceVisible = shift.externalVisible && shift.formattedTotalPay;
    const compensationOptionsVisible = shift.compensationOptions && shift.internalVisible;

    return (
        <div
            className="flex flex-col w-full space-y-[24px] py-large px-medium"
        >
            <div className="flex w-full flex-col mx-small">
                <div
                    className="flex flex-row items-center space-x-small"
                >
                    {shift.category ? <CategoryTag
                        text={shift.category.acronym} /> : null}
                    <p className="heading-md text-Text-Default">
                        {shift.title}
                    </p>
                </div>
               {shift.createdAt ? <p
                    className="info-caption text-Text-Subtle"
                >
                    {formatTimeSince(shift.createdAt)}
                </p> : null}
            </div>
            {poolAndInternalOnboarded &&
                <ShiftDetailsRow iconName='eye'>
                    <div
                        className="flex items-center flex-row justify-start"
                    >
                        {
                            shift.internalVisible &&
                            <ModalityTag
                                modality={ShiftModalityEnum.INTERNAL}
                                style={'mr-tiny'}
                            />
                        }
                        {
                            shift.externalVisible &&
                            <ModalityTag
                                modality={ShiftModalityEnum.EXTERNAL}
                            />
                        }
                    </div>
                </ShiftDetailsRow>
            }
            {shift.skills.length > 0 ? <ShiftDetailsRow iconName='stethoscope'>
                <div
                    className="flex-row space-x-small items-center justify-start gap-tiny flex-wrap inline-flex"
                >
                    {shift.skills.map((skill, index) =>
                        <TagLabel
                            key={index}
                            text={skill.displayText}
                        />)
                    }
                </div>

            </ShiftDetailsRow> : null}
            <div
                className="flex flex-row w-full space-x-small items-center"
            >
                <ShiftDetailsRow
                    iconName='calendar'
                    style={{
                        width: 'auto'
                    }}
                >
                    <p
                        className="body-regular"
                    >
                        {formatDate(shift.startTime)}
                    </p>
                </ShiftDetailsRow>
                <HolidayTag
                    holiday={shift.holiday}
                />
            </div>

            <ShiftDetailsRow iconName={shiftTimeInDayProps.icon} iconColor={shiftTimeInDayProps.color}>
                <p
                    className="body-regular"
                >
                    {shiftTimeInDayProps.name}
                </p>
            </ShiftDetailsRow>
            <ShiftDetailsRow iconName={'clock'}>
                <ScheduleComponent
                    style="body-regular space-x-small"
                    startTime={shift.startTime}
                    finishTime={shift.finishTime}
                />
            </ShiftDetailsRow>
            {shift.unit ? <ShiftDetailsRow iconName='nurse'>
                <div
                    className="flex flex-row w-full space-x-small items-center"
                >
                    <p
                        className="body-regular"
                    >
                        {shift.unit}
                    </p>
                    {
                        !shift.unitVisible ? <p
                            className="body-regular text-Text-Subtle"
                        >
                            ({HIDDEN_LABEL})
                        </p> : null
                    }
                </div>
            </ShiftDetailsRow> : null}
            {priceVisible || compensationOptionsVisible ? <ShiftDetailsRow iconName={'cash-banknote'}>
                {priceVisible ? <p
                    className="body-regular"
                >
                    {shift.formattedTotalPay}
                </p> : null}
                {priceVisible && compensationOptionsVisible ? <p
                    className="body-regular"
                >
                    |
                </p> : null}
                {shift.compensationOptions && shift.internalVisible ? <p
                    className="body-regular"
                >
                    {shift.compensationOptions.map(option => option.displayText).join(', ')}
                </p> : null}
            </ShiftDetailsRow> : null}
            {shift.details ? <ShiftDetailsRow iconName='blockquote' style={
                {
                    alignItems: 'flex-start'
                }
            }>
                <p
                    className="info-caption p-[4px]"
                >
                    {shift.details}
                </p>
            </ShiftDetailsRow> : null}
            {shift.externalId ?
                <div
                    className="flex flex-row space-x-small items-center justify-start"
                >
                    <div>
                        <ShiftDetailsRow iconName='hash'>
                            <p
                                className="body-regular"
                            >
                                {shift.externalId}
                            </p>
                        </ShiftDetailsRow>
                    </div>
                    <CopyButton
                        successMessage={IDENTIFIER_COPIED}
                        text={shift.externalId} />
                </div>

                : null
            }
        </div>
    )

}