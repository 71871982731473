import { CircularProgress } from "@mui/material";
import LivoIcon from "assets/LivoLogo";
import { PublishShiftModal } from "components/publishShift/PublishShiftModal";
import { EmptyShiftsState } from "components/calendar/EmptyShiftsState";
import { ShiftCard } from "components/shifts/ShiftCard";
import moment from "moment";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedShiftId } from "store/actions";
import { RootState } from "store/types";
import { ActionComponentIdEnum, Shift, ShiftTimeInDayEnum } from "types/shifts";
import { SHIFT_TIME_IN_DAY_DEFINITIONS, formatDate } from "utils";
import { EditShiftModal } from "components/shiftDetails/EditShiftModal";
import { ShiftConfiguration } from "components/publishShift/BaseShiftForm";
import { PastEmptyShiftsState } from "./PastShiftsEmptyState";
import { HolidayTag } from "components/shiftDetails/HolidayTag";
import { applyFilter } from "types/common/shiftFilters";

interface CalendarDayShiftsProps {
    shifts: Shift[];
    date: string;
    loading: boolean;
    reloadData: () => void;
    shadowReload: () => void;
    holiday?: boolean;

}

export const CalendarDayShifts: React.FC<CalendarDayShiftsProps> = ({
    shifts,
    date,
    loading,
    reloadData,
    shadowReload,
    holiday = false
}): JSX.Element => {
    const [publishShiftOpen, setPublishShiftOpen] = React.useState(false)
    const [selectedShiftTimeInDay, setSelectedShiftTimeInDay] = React.useState<ShiftTimeInDayEnum | null>(null)
    const { selectedShiftId } = useSelector((state: RootState) => state.calendarSummary)
    const [actionableShift, setActionableShift] = React.useState<Shift | null>(null)
    const [editShiftDetailsModalOpen, setEditShiftDetailsModalOpen] = React.useState(false)
    const dispatch = useDispatch()
    const shiftRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

    const filters = useSelector((state: RootState) => state.filterShifts.filters);
    let filteredShifts = shifts;
    filters.forEach((f) => filteredShifts = applyFilter(f, filteredShifts));

    useEffect(() => {
        if (selectedShiftId && shiftRefs.current[selectedShiftId]) {
            shiftRefs.current[selectedShiftId]?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selectedShiftId]);

    if (loading) {
        return (
            <div
                className="flex flex-1 justify-center items-center h-full"
            >
                <CircularProgress />
            </div>
        )
    }

    const handleOpen = () => {
        setPublishShiftOpen(true);
        setEditShiftDetailsModalOpen(false)
    };

    const handleClose = () => {
        setPublishShiftOpen(false);
        setActionableShift(null)
    };

    return (
        <div
            className="content flex flex-1 pt-[24px] px-medium overflow-y-auto no-scrollbar justify-center"
        >
            <div
                className="flex flex-col flex-1 max-w-[1024px]"
            >
                <div
                    className="flex flex-row items-center space-x-tiny  mb-large"
                >
                    <h1 className="heading-md">
                        {formatDate(date)}
                    </h1>
                    <HolidayTag holiday={holiday} />
                </div>
                <div className={selectedShiftId ? "flex flex-col space-y-large" : "flex flex-col lg:flex-row lg:space-x-large space-y-large lg:space-y-0"}>

                    {Object.keys(SHIFT_TIME_IN_DAY_DEFINITIONS).map((shiftTimeInDay, index) => {
                        const shiftsForTimeInDay = filteredShifts.filter((shift) => shift.shiftTimeInDay === shiftTimeInDay)
                        const props = SHIFT_TIME_IN_DAY_DEFINITIONS[shiftTimeInDay as ShiftTimeInDayEnum]
                        return <div key={index} className="mb-large flex-1">
                            <div
                                className="flex flex-row items-center justify-between mb-medium"
                            >
                                <div
                                    className="flex flex-row items-center space-x-tiny"
                                >
                                    <LivoIcon name={props.icon} size={24} color={props.color} />
                                    <h2 className="subtitle-regular">
                                        {props.name}
                                    </h2>
                                </div>
                                <button
                                    disabled={moment(date).isBefore(moment(), 'day')}

                                    onClick={() => {
                                        setSelectedShiftTimeInDay(shiftTimeInDay as ShiftTimeInDayEnum)
                                        handleOpen()
                                    }}
                                    className={`p-tiny ${moment(date).isBefore(moment(), 'day') ? 'opacity-0' : ''}`}
                                >
                                    <LivoIcon name="plus" size={24} color="#149EF2" />
                                </button>
                            </div>
                            <div
                                className="flex flex-col space-y-medium"
                            >
                                {shiftsForTimeInDay.length === 0 ?
                                    moment(date).isBefore(moment(), 'day') ?
                                        <PastEmptyShiftsState /> :
                                        <EmptyShiftsState
                                            date={moment(date).format('DD/MM/YYYY')}

                                            shiftTimeInDay={props.name}
                                            onClick={() => {
                                                setSelectedShiftTimeInDay(shiftTimeInDay as ShiftTimeInDayEnum)
                                                handleOpen()
                                            }}

                                        /> :
                                    shiftsForTimeInDay
                                        .map((shift, index) => {
                                            return <div key={index}
                                                ref={(el: any) => {
                                                    shiftRefs.current[shift.id] = el
                                                }}
                                            >
                                                <ShiftCard
                                                    shift={shift as Shift}
                                                    onClick={() => {
                                                        dispatch(setSelectedShiftId(shift.id))
                                                    }}
                                                    isSelected={selectedShiftId === shift.id}
                                                    actionComponents={[
                                                        {
                                                            id: ActionComponentIdEnum.EDIT,
                                                            iconName: 'pencil',
                                                            onClick: () => {
                                                                setActionableShift(shift)
                                                                setEditShiftDetailsModalOpen(true)
                                                                setPublishShiftOpen(false)
                                                            }
                                                        },
                                                        {
                                                            id: ActionComponentIdEnum.COPY,
                                                            iconName: 'copy',
                                                            onClick: () => {
                                                                setActionableShift(shift)
                                                                setEditShiftDetailsModalOpen(false)
                                                                setPublishShiftOpen(true)
                                                            }
                                                        }
                                                    ]}
                                                />
                                            </div>
                                        })}

                            </div>
                        </div>
                    })}
                    <div
                        style={{
                            height: '24px',
                        }}
                    >
                    </div>
                </div>
                <PublishShiftModal
                    isOpen={publishShiftOpen}
                    onClose={handleClose}
                    selectedDate={date}
                    shiftTimeInDay={selectedShiftTimeInDay || ShiftTimeInDayEnum.MORNING}
                    reloadData={reloadData}
                    shiftDetails={actionableShift ? {
                        ...actionableShift,
                        recurrentDates: actionableShift ? [moment(actionableShift.startTime).format('YYYY-MM-DD')] : [],
                        compensationOptions: actionableShift?.compensationOptions.map((option) => option.value) || []
                    } as ShiftConfiguration : undefined}
                />
                <EditShiftModal
                    isOpen={editShiftDetailsModalOpen && actionableShift !== null}
                    onClose={() => {
                        setEditShiftDetailsModalOpen(false)
                        setActionableShift(null)
                    }}
                    reloadData={() => {
                        shadowReload()
                    }}
                    shiftDetails={actionableShift}
                />
            </div>
        </div>
    )
}