import { ProfessionalProfile } from "types/claims"
import { ProfilePicture } from "../ProfilePicture"
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { ShiftModalityEnum } from "types";
import { InternalProfessional, internalProfessionalDisplayName } from "types/internal";
import { CategoryTag } from "components/common/CategoryTag";
import LivoIcon from "assets/LivoLogo";
import { CATEGORY_SECTION_LABEL, EMAIL_LABEL, MISSING_EMAIL_LABEL, NATIONAL_ID_LABEL, PERSONAL_DATA_LABEL, PHONE_LABEL, SKILLS_LABEL, SKILLS_SECTION_LABEL } from "locales/professionals/profile";
import { formatPhoneNumber } from "utils/utils";


interface ProfessionalCardHeaderProps {
    professionalProfile: ProfessionalProfile | InternalProfessional
    modality: ShiftModalityEnum | null;
}

export const ProfessionalCardHeader: React.FC<ProfessionalCardHeaderProps> = ({
    professionalProfile,
    modality
}) => {
    const accountInfo = useSelector((state: RootState) => state.account.accountInfo);
    
    function getNationalIdField() {
        return 'dataFields' in professionalProfile ? professionalProfile.dataFields.find((field: { key: string }) => field.key === 'NATIONAL_ID') : undefined
    }

    function displayName(professional: ProfessionalProfile | InternalProfessional) {
        if ('dataFields' in professional) {
            return internalProfessionalDisplayName(professional)
        }
        return `${professional.firstName}, ${professional.lastName}`
    }

    function shouldDisplayNationalId() {
        return 'dataFields' in professionalProfile && accountInfo?.facility.livoInternalOnboardingStrategy === 'DENARIO_INTEGRATED'
    }

    return (

        <div
            className="flex flex-col justify-center items-center w-full space-y-small"
        >
            <ProfilePicture profilePictureUrl={professionalProfile?.profilePictureUrl} modality={modality} size={64} />
            <div
                className="flex flex-col items-center w-full"
            >
                <p className="heading-md text-Text-Default">
                    {displayName(professionalProfile)}
                </p>

                {professionalProfile.category || professionalProfile.skills ?
                    <div
                        className="flex flex-col w-full space-y-large p-medium"
                    >
                        {shouldDisplayNationalId() && (
                            <>
                                <div className="flex flex-row space-x-small" >
                                    <LivoIcon name='user' size={24} color='#ACBBCB' />
                                    <p className="body-regular" >
                                        {PERSONAL_DATA_LABEL}
                                    </p>
                                </div>
                                <div className="flex items-center space-x-small">
                                    <div className="flex flex-row space-x-small justify-between items-start w-full">
                                        <p className="body-regular text-[#707A91]">
                                            {PHONE_LABEL}:
                                        </p>
                                        <p className="subtitle-regular text-Text-Default text-clip text-right" >
                                            {formatPhoneNumber(professionalProfile.phoneNumber)}
                                        </p>
                                    </div>
                                </div>
                                
                                {'email' in professionalProfile && (
                                    <div className="flex items-center space-x-small">
                                        <div className="flex flex-row space-x-small justify-between items-start w-full">
                                            <p className="body-regular text-[#707A91]">
                                                {EMAIL_LABEL}:
                                            </p>
                                            <p className="subtitle-regular text-Text-Default text-clip text-right" >
                                                {professionalProfile.email || MISSING_EMAIL_LABEL}
                                            </p>
                                        </div>
                                    </div>
                                )}

                                <div className="flex items-center space-x-small">
                                    <div className="flex flex-row space-x-small justify-between items-start w-full">
                                        <p className="body-regular text-[#707A91]">
                                            {NATIONAL_ID_LABEL}:
                                        </p>
                                        <p className="subtitle-regular text-Text-Default text-clip text-right" >
                                            {getNationalIdField()?.value}
                                        </p>
                                    </div>
                                </div>

                            </>
                        )}
                        
                        <div
                            className="flex flex-row space-x-small"
                        >
                            <LivoIcon name='stethoscope' size={24} color='#ACBBCB' />
                            <p
                                className="body-regular"
                            >
                                {professionalProfile.category ? CATEGORY_SECTION_LABEL : SKILLS_SECTION_LABEL}
                            </p>
                        </div>
                        {professionalProfile.category ? <div
                            className="flex items-center space-x-small"
                        >
                            <CategoryTag text={professionalProfile.category.acronym} />
                            <p
                                className="body-regular text-Text-Subtle"
                            >
                                {professionalProfile.category.displayText}
                            </p>
                        </div> : null}
                        {professionalProfile.skills ? <div
                            className="flex flex-row space-x-small justify-between items-start"
                        >
                            <p
                                className="body-regular text-[#707A91]"
                            >
                                {SKILLS_LABEL}:
                            </p>
                            <p
                                className="subtitle-regular text-Text-Default text-clip text-right"
                            >
                                {professionalProfile.skills.professionalSkills.map(skill => professionalProfile.skills.skillDefinitions.find(skillDefinition => skillDefinition.value === skill)?.displayText).join(", ")}
                            </p>
                        </div> : null}
                    </div>
                    : null}
            </div>

        </div>
    )
}