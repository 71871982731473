import LivoIcon from "assets/LivoLogo";
import { OfferClaim, OfferClaimStatus } from "types/offers";
import { OfferClaimButton } from "./OfferClaimButton";
import { useEffect, useRef, useState } from "react";
import { OfferProfessionalProfileSection } from "./OfferProfessionalProfile";
import { CALL_TO_DISCLOSE_DETAILS } from "locales/offers";
import { DiscloseDetailsModal } from "./DiscloseDetailsModal";
import { updateOfferClaimStatus } from "services/offers";
import { RootState } from "store/types";
import { useSelector } from "react-redux";
import { HireOfferClaimModal } from "./HireOfferClaimModal";
import { RejectOfferClaimModal } from "./RejectOfferClaimModal";

interface OfferClaimDetailProps {
    selectedOfferClaim: OfferClaim;
    onClose: () => void;
    refreshClaims: () => void;
}

export const OfferClaimDetail: React.FC<OfferClaimDetailProps> = ({ 
    selectedOfferClaim,
    onClose,
    refreshClaims,
}) => {
    const { selectedOfferId } = useSelector((state: RootState) => state.offerList)
    const [isVisible, setIsVisible] = useState(false);
    const [isReviewDetailModalOpen, setIsReviewDetailModalOpen] = useState(false);
    const [isHireModalOpen, setIsHireModalOpen] = useState(false);
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    useEffect(() => {
        const element = document.getElementById('offer-claim-detail');
        if (element) {
            element.style.scrollBehavior = 'smooth';
            element.scrollTop = 0;
            element.style.scrollBehavior = 'auto';
        }
    }, [selectedOfferClaim]);

    function getFirstName(fullName: string) {
        return fullName.split(' ')[0];
    }

    function discloseOfferDetail(offerId: number, claimId: number) {
        updateOfferClaimStatus(offerId, claimId, OfferClaimStatus.DETAILS_DISCLOSED).then(() => {
            setIsReviewDetailModalOpen(false);
            refreshClaims();
        })
    }

    function hireOfferClaim(offerId: number, claimId: number) {
        updateOfferClaimStatus(offerId, claimId, OfferClaimStatus.HIRED ).then(() => {
            setIsHireModalOpen(false);
            refreshClaims();
        })
    }

    function rejectOfferClaim(offerId: number, claimId: number, reason: string, details: string) {
        updateOfferClaimStatus(offerId, claimId, OfferClaimStatus.REJECTED, reason, details).then(() => {
            setIsRejectModalOpen(false);
            refreshClaims();
        })
    }

    return (
        <div className="flex flex-1 h-full max-w-[1024px]">
            <div className={`flex flex-1 flex-col bg-white border-l-[1px] border-solid border-Divider-Subtle overflow-y-auto no-scrollbar h-full transform transition-transform duration-300 ${isVisible ? 'translate-x-0' : 'translate-x-full'}`}>
                <div className="h-96 p-6 flex-col justify-start items-center gap-2 inline-flex">
                    <div className=" self-stretch justify-between items-start inline-flex">
                        <button onClick={() => {
                            setIsVisible(false);
                            setTimeout(() => onClose(), 300);
                        }}>
                            <LivoIcon name='close' size={24} color='#707A91' />
                        </button>
                        <div className="justify-end items-start gap-2 flex">
                            {selectedOfferClaim.status === 'VISIBLE' && (
                                <OfferClaimButton 
                                    label="Mostrar detalles" 
                                    color="white" 
                                    backgroundColor="#149ef2" 
                                    onClick={
                                        () => {
                                            setIsReviewDetailModalOpen(true);
                                        }
                                    } 
                                />
                            )}
                            
                            {selectedOfferClaim.status === 'DETAILS_DISCLOSED' && (
                                <OfferClaimButton 
                                    label="Marcar como contratado" 
                                    color="white" 
                                    backgroundColor="#149ef2" 
                                    onClick={() => {
                                        setIsHireModalOpen(true);
                                    }} 
                                />
                            )}

                            {(selectedOfferClaim.status === 'VISIBLE' || selectedOfferClaim.status === 'DETAILS_DISCLOSED') && (
                                <OfferClaimButton 
                                    label="Descartar" 
                                    color="#2c3038" 
                                    backgroundColor="#ffc6c7" 
                                    onClick={() => {
                                        setIsRejectModalOpen(true);
                                    }} 
                                />
                            )}
                            
                        </div>
                    </div>
                    <OfferProfessionalProfileSection 
                        profile={selectedOfferClaim?.professionalProfile}
                        detailsDisclosed={selectedOfferClaim.status == 'DETAILS_DISCLOSED' || selectedOfferClaim.status == 'HIRED'}
                        callToActionText={selectedOfferClaim.status == 'VISIBLE' ? CALL_TO_DISCLOSE_DETAILS : null}
                        status={selectedOfferClaim.status}
                     />
                </div>

            </div>

            <DiscloseDetailsModal
                    professionalName={getFirstName(selectedOfferClaim?.professionalProfile?.fullName)}
                    isOpen={isReviewDetailModalOpen}
                    onClose={() => {
                        setIsReviewDetailModalOpen(false);
                    }}
                    onAccept={() => {
                        discloseOfferDetail(selectedOfferId!!, selectedOfferClaim.id);
                    }}
                />
                <HireOfferClaimModal
                    isOpen={isHireModalOpen}
                    onClose={() => {
                        setIsHireModalOpen(false);
                    }}
                    onAccept={() => {
                        hireOfferClaim(selectedOfferId!!, selectedOfferClaim.id);
                    }}
                />
                <RejectOfferClaimModal
                    professionalName={getFirstName(selectedOfferClaim?.professionalProfile?.fullName)}
                    isOpen={isRejectModalOpen}
                    onClose={() => {
                        setIsRejectModalOpen(false);
                    }}
                    rejectOfferClaim={
                        (reason: string, details: string) => {
                            rejectOfferClaim(selectedOfferId!!, selectedOfferClaim.id, reason, details);
                        }
                    }
                />
        </div>
    );
}