import LivoIcon from "assets/LivoLogo";
import { InfoSection } from "./InfoSection";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { ChangePasswordComponent } from "./ChangePassword";
import { useState } from "react";
import { CONFIGURATION_SCREEN_TITLE, LAST_TIME_SIGN_IN, SETTINGS_SCREEN_CHANGE_PASSWORD_HEADER, SETTINGS_SCREEN_FACILITY_INFO, SETTINGS_SCREEN_INFORMATION, SETTINGS_SCREEN_SETTINGS_CONFIG } from "locales/settings";
import { ModalContainer } from "components/common/ModalContainer";
import { TagLabel } from "components/common/TagLabel";
import moment from "moment";
import { UserFeatureEnum } from "services/account";
import { MANAGE_FAVORITE_PROFESSIONALS_TITLE } from "locales/professionals/favorite";
import FavoriteProfessionalsModal from "components/professionals/FavoriteProfessionalsModal";

interface AccountModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const AccountModal: React.FC<AccountModalProps> = ({
    isOpen,
    onClose
}) => {
    const { accountInfo } = useSelector((state: RootState) => state.account)
    const [isChangingPassword, setIsChangingPassword] = useState(false)
    const [favoriteProfessionalsModalOpen, setFavoriteProfessionalsModalOpen] = useState(false);

    const favoriteProfessionalsModal = <FavoriteProfessionalsModal
        isOpen={favoriteProfessionalsModalOpen}
        handleClose={() => setFavoriteProfessionalsModalOpen(false)}
    />

    return accountInfo && (
        <ModalContainer
            isOpen={isOpen}
            style={{ width: '60%', maxWidth: '700px' }}
            onClose={onClose}
        >
            <div
                className="flex flex-col  bg-white rounded-[16px] mx-auto "

            >
                <div
                    className="flex flex-row justify-between items-center p-large border-b border-Divider-Default"
                >
                    {isChangingPassword ?
                        <div
                            className="flex items-center space-x-small"
                        >
                            <button
                                onClick={() => setIsChangingPassword(false)}
                            >
                                <LivoIcon name='arrow-left' size={24} color='#707A91' />
                            </button>
                            <p
                                className="heading-md"
                            >
                                {SETTINGS_SCREEN_CHANGE_PASSWORD_HEADER}
                            </p>
                        </div> :
                        <p
                            className="heading-md"
                        >
                            {CONFIGURATION_SCREEN_TITLE}
                        </p>}
                    <button
                        onClick={() => {
                            setIsChangingPassword(false)
                            onClose()
                        }}
                        className="flex items-center justify-center"
                    >
                        <LivoIcon name='close' size={24} color='#707A91' />
                    </button>
                </div>
                <div
                    className="flex max-h-[500px] overflow-y-auto no-scrollbar pb-large"
                >
                    {
                        isChangingPassword ? <ChangePasswordComponent
                            errorMessage=""
                            onSubmit={() => {
                                onClose();
                                setIsChangingPassword(false)
                            }} /> :
                            <div
                                className="flex flex-col p-large space-y-medium bg-Background-Secondary flex-1"
                            >
                                <InfoSection
                                    title={SETTINGS_SCREEN_INFORMATION}
                                    infoItems={[
                                        {
                                            iconName: 'user',
                                            label: `${accountInfo.firstName} ${accountInfo.lastName}`,
                                        },
                                        {
                                            iconName: 'mail',
                                            label: accountInfo.email,
                                        },

                                    ]} />
                                <InfoSection
                                    title={SETTINGS_SCREEN_SETTINGS_CONFIG}
                                    infoItems={[
                                        ...(accountInfo?.userFeatures?.includes(UserFeatureEnum.FAVOURITE_PROFESSIONALS_MANAGEMENT)
                                            ? [{
                                                iconName: 'heart',
                                                label: MANAGE_FAVORITE_PROFESSIONALS_TITLE,
                                                onClick: () => {
                                                    setFavoriteProfessionalsModalOpen(true)
                                                }
                                            }]
                                            : []
                                        ),
                                        {
                                            iconName: 'key',
                                            label: SETTINGS_SCREEN_CHANGE_PASSWORD_HEADER,
                                            onClick: () => {
                                                setIsChangingPassword(true)
                                            }
                                        },
                                    ]} />
                                <InfoSection
                                    title={SETTINGS_SCREEN_FACILITY_INFO}
                                    infoItems={[
                                        {
                                            iconName: 'internal-hospital',
                                            label: accountInfo.facility.name,
                                        },

                                        ...(accountInfo?.units?.length > 0
                                            ? [
                                                {
                                                    iconName: 'nurse',
                                                    children: (
                                                        <div className="flex flex-wrap w-full">
                                                            {accountInfo.units.map((unit, index) => (
                                                                <div className="mr-tiny my-[2px]" key={index}>
                                                                    <TagLabel text={unit.displayName} />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ),
                                                },
                                            ]
                                            : []),

                                        {
                                            iconName: 'map',
                                            label: accountInfo.facility.address,
                                            onClick: () => {
                                                {
                                                    window.open(accountInfo.facility.mapLink, '_blank', 'noopener,noreferrer');
                                                }
                                            }
                                        },
                                    ]} />


                            </div>
                    }
                </div>

                {accountInfo.lastTimeSignIn && !isChangingPassword ? <p
                    className="info-caption text-Text-Subtle p-medium text-center"
                >
                    {LAST_TIME_SIGN_IN}{moment(accountInfo.lastTimeSignIn).format('DD/MM/YYYY HH:mm')}
                </p> : null}
                {favoriteProfessionalsModal}
            </div>
        </ModalContainer >

    )
}