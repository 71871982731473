import LivoIcon from "assets/LivoLogo";
import { CategoryTag } from "components/common/CategoryTag";
import { CopyButton } from "components/common/CopyButton";
import { TagLabel } from "components/common/TagLabel";
import { OfferStatusTag } from "components/offerlist/OfferStatusTag";
import { OFFER_COMPENSATION, OFFER_DESCRIPTION, OFFER_DURATION, OFFER_ID, OFFER_PERKS, OFFER_REQUIREMENTS, OFFER_STATUS_CLOSED, OFFER_STATUS_PUBLISHED } from "locales/offers";
import { IDENTIFIER_COPIED } from "locales/shiftList";
import React from "react";
import { OfferDetail, OfferStatus } from "types/offers";

interface OfferInfoCardProps {
  offer: OfferDetail;
}

const OfferInfoCard: React.FC<OfferInfoCardProps> = ({ offer }) => {
  return (
    <div className="bg-white rounded-lg p-6 w-full max-w-2xl mx-auto my-6 overflow-y-auto">
      {/* Header */}
      <div className="flex items-center mb-[24px]">
        <CategoryTag text={offer.category.acronym} />
        <h1 className="heading-md ml-small">
          {offer.category.displayText}
          {offer.category.displayText && offer.duration.contractDuration && " · "}
          {offer.duration.contractDuration}
        </h1>
      </div>

      {/* Status */}
      <div className="flex items-center mb-[24px]">
        <OfferStatusTag status={offer.status} />
      </div>

      {/* Duration */}
      <div className="mb-[24px]">
        <h2 className="subtitle-regular mb-small">{OFFER_DURATION}</h2>
        <div className="flex items-center mb-small">
          <LivoIcon name={"calendar-month"} size={24} color={"#ACBBCB"} />
          <p className="body-regular ml-small">
            {offer.duration.contractDuration}
          </p>
        </div>
        <div className="flex items-center mb-small">
          <LivoIcon name={"clock"} size={24} color={"#ACBBCB"} />
          <p className="body-regular ml-small">
            {offer.duration.contractDates}
          </p>
        </div>
        <div className="flex items-center">
          <LivoIcon name={"clock"} size={24} color={"#ACBBCB"} />
          <p className="body-regular ml-small">
            {offer.duration.summary}
          </p>
        </div>
      </div>

      {/* Compensation */}
      <div className="mb-[24px]">
        <h2 className="subtitle-regular mb-small">{OFFER_COMPENSATION}</h2>
        <div className="flex items-center">
          <LivoIcon name={"cash-banknote"} size={24} color={"#ACBBCB"} />
          <p className={`body-regular ${offer.compensation.defined ? "text-Green-700" : "text-Text-Subtle"} ml-small`}>
            {offer.compensation.displayText}
          </p>
        </div>
      </div>

      {/* Perks */}
      <div className="mb-[24px]">
        <h2 className="subtitle-regular mb-small">{OFFER_PERKS}</h2>
        <div className="flex flex-wrap gap-[4px]">
          {offer.perks.map((perk) => (
            <TagLabel
              text={perk.displayText}
              icon={perk.icon}
            />
          ))}
        </div>
      </div>

      {/* Requirements */}
      <div className="mb-[24px]">
        <h2 className="subtitle-regular mb-small">{OFFER_REQUIREMENTS}</h2>
        {offer.requirements.skills.map((skill, index) => (
          <p key={index} className="flex items-center text-gray-700 mb-1">
            <LivoIcon name={"stethoscope"} size={24} color={"#ACBBCB"} />
            <div className="ml-small">
              <TagLabel text={skill.displayText} />
            </div>
            <span className="info-caption text-Text-Subtle ml-small">
              {skill.skillRequirement}
            </span>
          </p>
        ))}
        <p className="body-regular ml-[36px]">{offer.requirements.otherRequirements}</p>
      </div>

      {/* Description */}
      <div className="mb-4">
        <h2 className="subtitle-regular mb-small">{OFFER_DESCRIPTION}</h2>
        <div className="flex">
          <div className="flex-shrink-0">
            <LivoIcon name={"blockquote"} size={24} color={"#ACBBCB"} />
          </div>
          <span className="body-regular ml-small">{offer.description}</span>
        </div>
      </div>

      {/* Offer ID */}
      <div>
        <h2 className="subtitle-regular mb-small">{OFFER_ID}</h2>
        <p className="flex">
          <div className="flex-shrink-0">
            <LivoIcon name={"hash"} size={24} color={"#ACBBCB"} />
          </div>
          <span className="body-regular ml-small">{offer.externalId}</span>
          <CopyButton
            text={offer.externalId}
            successMessage={IDENTIFIER_COPIED}
            style={{
              marginLeft: '8px'
            }}
          />
        </p>
      </div>
    </div>
  );
};

export default OfferInfoCard;
