import { TagLabel } from "components/common/TagLabel";

interface OfferClaimStatusPros {
    status: 'VISIBLE' | 'DETAILS_DISCLOSED' | 'REJECTED' | 'HIRED'
}

export const OfferClaimStatusTag: React.FC<OfferClaimStatusPros> = ({ status }) => {
    const offerStatusBackground = {
        'VISIBLE': '#E0EFFF',
        'DETAILS_DISCLOSED': '#C2F0C6',
        'REJECTED': '#EEEFF3',
        'HIRED': '#33B240'
    }
    const offerStatusColor = {
        'VISIBLE': '#2C3038',
        'DETAILS_DISCLOSED': '#2C3038',
        'REJECTED': '#2C3038',
        'HIRED': '#FFFFFF'

    }
    const offerStatusText = {
        'VISIBLE': 'Nuevo',
        'DETAILS_DISCLOSED': 'Finalista',
        'REJECTED': 'Descartado',
        'HIRED': 'Contratado'
    }

    return (
        <TagLabel text={offerStatusText[status]} color={offerStatusColor[status]} backgroundColor={offerStatusBackground[status]} />
    )
}