import { ShiftModalityEnum } from "types";
import { Category, SpecializationDTO } from "./shifts";
import { SkillDefinition } from "services/account";

type ProfessionalTag = {
  label: string;
  styling: {
    backgroundColor?: string;
    icon?: string;
    textColor?: string;
  } | null;
}

export type ProfessionalDataField = {
  key: string,
  label: string,
  value: string,
  values: [
    {
      value: string,
      displayText: string
    }
  ],
  displayText: string,
  editable: boolean
}

export interface ProfessionalProfile {
  id: number;
  firstName: string;
  lastName: string;
  profilePictureUrl: string;
  phoneNumber: string;
  licenseNumber: string;
  professionalCV: string;
  status: string;
  professionalReview: ProfessionalReviewInfo,
  totalPerformedShifts: number;
  firstShifterForFacility: boolean;
  tags: ProfessionalTag[]
  internal: {
    dataFields: ProfessionalDataField[]
  } | null;
  totalShiftsInFacility: {
    totalShiftsInFacility: number,
    shiftsInFacility: [
      {
        unit: string,
        numberOfShifts: number
      }
    ],
    facilityName: string
  }
  category: Category;
  favorite?: boolean;
  modality?: ShiftModalityEnum;
  skills: {
    professionalSkills: string[],
    skillDefinitions: SkillDefinition[]
  };
  availableCVTypes: CVType[];
  cvSummary?: string | null;
}

export type ReviewFeedback = {
  generalRating: number,
  feedback: string,
}


export type ProfessionalReview = {
  facilityName: string,
  specialization: SpecializationDTO
  review: ReviewFeedback,
  month: string,
  year: number,
};


export type ProfessionalReviewInfo = {
  averageRating: number,
  totalReviews: number,
  reviews: ProfessionalReview[]
}

enum CompensationOptionType {
  EXTRA_PAY = "EXTRA_PAY",
  TIME_OFF = "TIME_OFF",
  SHIFT_SWAP = "SHIFT_SWAP"
}

export type CompensationOption = {
  type: CompensationOptionType,
  label: string,
  compensationValue: string;
}

export enum ClaimStatusEnunm {
  APPROVED = 'APPROVED',
  PENDING_APPROVAL = 'PENDING_APPROVAL'
}

export type SlotReason = {
  value?: string;
  displayText: string;
  comment?: string;
}
export type SlotReasonOption = {
  value: string;
  displayText: string;
}
export interface ClaimRequest {
  claimedAt: string;
  id: 0;
  status: string;
  statusUpdatedAt: string;
  professionalProfile: ProfessionalProfile;
  compensationOption: CompensationOption | null;
  modality: ShiftModalityEnum | null;
  livoPoolOnboarded: boolean;
  livoInternalOnboarded: boolean;
  cancellationRequest: {
    requestedAt: string,
    reason: string
  }
  slotReason: SlotReason | null;
  slotReasonOptions?: SlotReasonOption[];
  slotReasonCommentDisplayed: boolean;
}

export enum CVType {
  LIVO_CV = 'LIVO_CV',
  PDF_UPLOAD = 'PDF_UPLOAD'
}
