import { OfferClaimRejectReason , Offer, OfferClaim, OfferClaimsResponse, OfferClaimStatus, OfferDetail, OfferListingResponse, OfferStatus } from "types/offers";
import { api, handleApiError } from "./api";
import { SLOT_REASON_COMMENT } from "locales/shiftClaimDetails/es";
import { CVType } from "types/claims";

type Params = {
    status?: string;
}

export function fetchOffers(
    status?: string,
): Promise<OfferListingResponse> {
    // return mockFetchOffers(status)

    const uri = '/facility/portal/offers';
    const params: Params = {};
    if (status && status !== 'ALL') {
        params.status = status;
    }

    return api
        .get(uri, { params })
        .then(response => {
            return response.data;
        })
        .catch(handleApiError);
}

export function fetchOfferClaims(
    offerId: number,
    status?: string,
): Promise<OfferClaimsResponse> {
    // return mockFetchOfferClaims(offerId, status)

    const uri = `/facility/portal/offers/${offerId}/claims`;
    const params: Params = {};
    if (status && status !== 'ALL') {
        params.status = status;
    }

    return api
        .get(uri, { params })
        .then(response => {
            return response.data;
        })
        .catch(handleApiError);
}

export function fetchOfferDetail(
    offerId: number,
): Promise<OfferDetail> {
    // return mockFetchOfferDetail(offerId)

    const uri = `/facility/portal/offers/${offerId}`;

    return api
        .get(uri)
        .then(response => {
            return response.data;
        })
        .catch(handleApiError);
}

interface UpdateOfferClaimParams {
    newStatus: OfferClaimStatus,
    reason?: string,
    reasonComment?: string,
}

export function updateOfferClaimStatus(
    offerId: number,
    claimId: number,
    newStatus: OfferClaimStatus,
    reason?: string,
    reasonComment?: string,
): Promise<void> {
    return api.post(`/facility/portal/offers/${offerId}/claims/${claimId}`, { newStatus, reason, reasonComment })
        .then(response => {
            return response.data;
        })
        .catch(handleApiError);
}

export function fetchOfferClaimRejectReasons(): Promise<OfferClaimRejectReason[]> {
    return mockFetchCancelOfferReasons()

    return api.get('/facility/common/offer-claim-reject-reasons')
        .then(response => {
            return response.data;
        })
        .catch(handleApiError);
}

export function mockFetchCancelOfferReasons(): Promise<OfferClaimRejectReason[]> {
    return new Promise((resolve) => {
        setTimeout(() => {
            return resolve(mockCancelOfferReasons);
        }, 1000);
    });
}

const mockCancelOfferReasons: OfferClaimRejectReason[] = [
    {displayText: 'No tiene suficiente experiencia', value: 'NO_SUFFICIENT_EXPERIENCE'},
    {displayText: 'Trabajará en otra posición', value: 'PROFESSIONAL_WORK_IN_OTHER_POSITION'},
    {displayText: 'El puesto ya está cubierto', value: 'POSITION_ALREADY_COVERED'},
    {displayText: 'Mala experiencia con el profesional', value: 'BAD_EXPERIENCE_WITH_PROFESSIONAL'},
    {displayText: 'Other', value: 'OTHER'},
];

export function mockFetchOffers(
    status?: string,
): Promise<OfferListingResponse> {
    return new Promise((resolve) => {
        setTimeout(() => {
            const filterOffers: Offer[] = mockOffers.filter((offer) => {
                if (status === 'ALL') {
                    return true
                }
                return offer.status === status
            })
            return resolve({
                total: filterOffers.length,
                rows: filterOffers
            })
        }, 1000)
    })
}

export function mockFetchOfferClaims(
    offerId: number,
    status?: string,
): Promise<OfferClaimsResponse> {
    return new Promise((resolve) => {
        setTimeout(() => {
            const filterClaims: OfferClaim[] = mockClaims.filter((claim) => {
                if (status === 'ALL') {
                    return true
                }
                if (status === 'CLOSED') {
                    return claim.status === 'REJECTED'
                }
                return true
            })
            return resolve({
                total: filterClaims.length,
                totalNewClaims: filterClaims.filter((claim) => claim.newClaim).length,
                rows: filterClaims
            })
        }, 1000)
    })
}

export function mockFetchOfferDetail(offerId: number): Promise<OfferDetail> {
    return new Promise((resolve) => {
        setTimeout(() => {
            const offer = mockOfferDetails.find((offer) => offer.id === offerId)
            if (offer != null) {
                return resolve(offer)
            }
            throw new Error('Offer not found')
        }, 1000)
    })
}

const mockOfferDetails: OfferDetail[] = [
    {
        id: 1,
        externalId: '1',
        category: {
            code: '1',
            acronym: 'ENF',
            displayText: 'Category A',
        },
        status: OfferStatus.PUBLISHED,
        duration: {
            contractDuration: 'Contract',
            contractDates: '1 month',
            summary: '1 month contract'
        },
        compensation: {
            displayText: '1000$',
            defined: true
        },
        perks: [
            {
                displayText: 'Perk 1',
                icon: 'meal'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            },
            {
                displayText: 'Perk 2',
                icon: 'locker'
            }
        ],
        requirements: {
            skills: [
                {
                    displayText: 'Skill 1',
                    skillRequirement: 'Skill requirement 1'
                },
                {
                    displayText: 'Skill 2',
                    skillRequirement: 'Skill requirement 2'
                },
                {
                    displayText: 'Skill 3',
                    skillRequirement: 'Skill requirement 2'
                }
            ],
            otherRequirements: 'Other requirements'
        },
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
        totalNewClaims: 2
    },
    {
        id: 2,
        externalId: '2',
        category: {
            code: '2',
            acronym: 'ENF',
            displayText: 'Category B',
        },
        status: OfferStatus.PUBLISHED,
        duration: {
            contractDuration: 'Contract',
            contractDates: '2 weeks',
            summary: '2 weeks contract'
        },
        compensation: {
            displayText: '35.000€ ~ 36.000€',
            defined: true
        },
        perks: [
            {
                displayText: 'Perk 1',
                icon: 'icon'
            },
            {
                displayText: 'Perk 2',
                icon: 'icon'
            }
        ],
        requirements: {
            skills: [
                {
                    displayText: 'Skill 3',
                    skillRequirement: 'Skill requirement 3'
                },
                {
                    displayText: 'Skill 4',
                    skillRequirement: 'Skill requirement 4'
                }
            ],
            otherRequirements: 'Other requirements'
        },
        description: 'Description',
        totalNewClaims: 0
    },
    {
        id: 3,
        externalId: '3',
        category: {
            code: '3',
            acronym: 'ENF',
            displayText: 'Category C',
        },
        status: OfferStatus.CLOSED,
        duration: {
            contractDuration: 'Permanent job',
            contractDates: 'Permanent job',
            summary: 'Permanent job'
        },
        compensation: {
            displayText: 'Salario no disponible',
            defined: false
        },
        perks: [
            {
                displayText: 'Perk 1',
                icon: 'icon'
            },
            {
                displayText: 'Perk 2',
                icon: 'icon'
            }
        ],
        requirements: {
            skills: [
                {
                    displayText: 'Skill 5',
                    skillRequirement: 'Skill requirement 5'
                },
                {
                    displayText: 'Skill 6',
                    skillRequirement: 'Skill requirement 6'
                }
            ],
            otherRequirements: 'Other requirements'
        },
        description: 'Description',
        totalNewClaims: 1
    },
    {
        id: 4,
        externalId: '4',
        category: {
            code: '4',
            acronym: 'ENF',
            displayText: 'Category D',
        },
        status: OfferStatus.PUBLISHED,
        duration: {
            contractDuration: 'Contract',
            contractDates: '10 years',
            summary: '10 years contract'
        },
        compensation: {
            displayText: '4000$',
            defined: true
        },
        perks: [
            {
                displayText: 'Perk 1',
                icon: 'icon'
            },
            {
                displayText: 'Perk 2',
                icon: 'icon'
            }
        ],
        requirements: {
            skills: [
                {
                    displayText: 'Skill 7',
                    skillRequirement: 'Skill requirement 7'
                },
                {
                    displayText: 'Skill 8',
                    skillRequirement: 'Skill requirement 8'
                }
            ],
            otherRequirements: 'Other requirements'
        },
        description: 'Description',
        totalNewClaims: 0
    },
    {
        id: 5,
        externalId: '5',
        category: {
            code: '5',
            acronym: 'ENF',
            displayText: 'Category E',
        },
        status: OfferStatus.PUBLISHED,
        duration: {
            contractDuration: 'Contract',
            contractDates: '5 months',
            summary: '5 months contract'
        },
        compensation: {
            displayText: 'Según experiencia',
            defined: false
        },
        perks: [
            {
                displayText: 'Perk 1',
                icon: 'icon'
            },
            {
                displayText: 'Perk 2',
                icon: 'icon'
            }
        ],
        requirements: {
            skills: [
                {
                    displayText: 'Skill 9',
                    skillRequirement: 'Skill requirement 9'
                },
                {
                    displayText: 'Skill 10',
                    skillRequirement: 'Skill requirement 10'
                }
            ],
            otherRequirements: 'Other requirements'
        },
        description: 'Description',
        totalNewClaims: 0
    }
]


const mockOffers: Offer[] = [
    {
        id: 1,
        category: {
            code: '1',
            acronym: 'ENF',
            displayText: 'Category A',
        },
        skills: ["skill1", "skill2"],
        duration: "1 month",
        compensation: {
            displayText: "1000$",
            defined: true
        },
        status: OfferStatus.PUBLISHED,
        totalNewClaims: 2
    },
    {
        id: 2,
        category: {
            code: '2',
            acronym: 'ENF',
            displayText: 'Category B',
        },
        skills: ["skill3", "skill4"],
        duration: "2 weeks",
        compensation: {
            displayText: "35.000€ ~ 36.000€",
            defined: true
        },
        status: OfferStatus.PUBLISHED,
        totalNewClaims: 0
    },
    {
        id: 3,
        category: {
            code: '3',
            acronym: 'ENF',
            displayText: 'Category C',
        },
        skills: ["skill5", "skill6"],
        duration: "Permanon job",
        compensation: {
            displayText: "Salario no disponible",
            defined: false
        },
        status: OfferStatus.CLOSED,
        totalNewClaims: 1
    },
    {
        id: 4,
        category: {
            code: '4',
            acronym: 'ENF',
            displayText: 'Category D',
        },
        skills: ["skill7", "skill8"],
        duration: "10 years",
        compensation: {
            displayText: "4000$",
            defined: true
        },
        status: OfferStatus.PUBLISHED,
        totalNewClaims: 0
    },
    {
        id: 5,
        category: {
            code: '5',
            acronym: 'ENF',
            displayText: 'Category E',
        },
        skills: ["skill9", "skill10"],
        duration: "5 months",
        compensation: {
            displayText: "Según experiencia",
            defined: false
        },
        status: OfferStatus.PUBLISHED,
        totalNewClaims: 0
    }
]


const statuses = [
    OfferClaimStatus.VISIBLE,
    OfferClaimStatus.REJECTED,
    OfferClaimStatus.HIRED,
    OfferClaimStatus.DETAILS_DISCLOSED,
];

const mockClaims: OfferClaim[] = Array.from({ length: 40 }, (_, index) => ({
    id: index + 1,
    status: statuses[Math.floor(Math.random() * statuses.length)],
    newClaim: Math.random() > 0.5,
    professionalProfile: {
        id: index + 1,
        modality: `Modality ${index + 1}`,
        skills: [`skill${index * 2 + 1} not long`, `skill${index * 2 + 2} really long skill here and there`, `skill${index * 2 + 3}` ],
        fullName: `John Doe ${index + 1} A B`,
        email: `john.doe${index + 1}@example.com`,
        phoneNumber: `12345678${index}`,
        profilePictureUrl: `https://via.placeholder.com/150?text=Profile+${index + 1}`,
        licenseNumber: `12345678${index}`,
        professionalCV: `Professional CV ${index + 1}`,
        cvSummary: `CV Summary ${index + 1} Enfermera con experiencia en atención al paciente en entornos hospitalarios y ambulatori Enfermera con experiencia en atención al paciente en entornos hospitalarios y ambulatori`,
        professionalReview: {
            totalReviews: Math.floor(Math.random() * 100),
            averageRating: 3,
            reviews: Array.from({ length: 5 }, (_, index) => ({
                facilityName: `Facility ${index + 1}`,
                specialization: {
                    name: "Operationing room",
                    translationDefault: "Operationing room",
                    translations: {
                        es: "Operationing room"
                    }
                },
                review: {
                    generalRating: Math.floor(Math.random() * 5) + 1,
                    feedback: `Feedback ${index + 1}`,
                },
                month: 'January',
                year: 2022,
            })),
        },
        totalShiftsInFacility: Math.floor(Math.random() * 20),
        favorite: Math.random() > 0.5,
        availableCVTypes: [CVType.LIVO_CV, CVType.PDF_UPLOAD]
    }
}));