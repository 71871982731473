import LivoIcon from "assets/LivoLogo";
import { CV_SUMMARY_TITLE } from "locales/professionalClaim";
import { ICON_GRAY } from "utils/colors";

interface ProfessionalCVSummaryProps {
  cvSummary: string;
  className?: string;
}

export function ProfessionalCVSummary({
  cvSummary,
  className
}: ProfessionalCVSummaryProps) {
  return (
    <div className={"p-medium " + (className || '')}>
      <div className="flex flex-row items-center space-x-small mb-large">
        <LivoIcon name="sparkles" size={24} color={ICON_GRAY} />
        <p className="body-regular">{CV_SUMMARY_TITLE}</p>
      </div>
      <p className="body-regular text-[#707A91] mb-small">{cvSummary}</p>
    </div>
  )
}