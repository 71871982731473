import LivoIcon from "assets/LivoLogo";
import { useEffect, useState } from "react";
import { OfferSideBarMenu } from "./OfferSideBarMenu";
import { OFFER_CANDIDATES, OFFER_DETAILS } from "locales/offers";
import { OfferClaimsList } from "./OfferClaimsList";
import { OfferDetail } from "types/offers";
import OfferInfoCard from "./OfferInfoCard";

interface OfferDetailSectionProps {
    offer: OfferDetail | null;
    loadingOffer: boolean;
    selectedOfferId: number;
    goBack: () => void;
}

export const OfferDetailSection: React.FC<OfferDetailSectionProps> = ({ 
    offer,
    loadingOffer,
    selectedOfferId,
    goBack
}) => {
    enum Tab {
        CANDIDATES = 'CANDIDATE',
        DETAILS = 'DETAILS'
    }
    const [selectedTab, setSelectedTab] = useState(Tab.CANDIDATES)
    
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    return (
        <div className={`flex w-full transform transition-transform duration-300 ${isVisible ? 'translate-x-0' : 'translate-x-full'}`}>
            {/* Side bar */}
            <div className="flex flex-col bg-white h-full justify-start pt-[20px] px-medium self-start border-r-[1px] border-solid border-Divider-Subtle w-[256px] space-y-small">
                <div className="px-1 py-2 justify-start items-start gap-1 inline-flex">
                    <button onClick={
                        () => {
                            setIsVisible(false);
                            setTimeout(() => goBack(), 300);
                        }
                    } className="w-6 h-6 relative" >
                        <LivoIcon name='arrow-left' size={24} color='#ACBBCB' />
                    </button>
                    <div className="grow shrink basis-0 text-[#2c3038] text-base font-semibold font-['Roboto'] leading-normal">
                        {offer?.requirements.skills[0]?.displayText}
                        {offer?.requirements.skills[0]?.displayText && offer?.duration.contractDuration && " · "}
                        {offer?.duration.contractDuration}
                    </div>
                </div>
                <div className="h-24 px-3 flex-col justify-start items-start gap-2 inline-flex">
                    <div className="self-stretch h-24 flex-col justify-start items-start flex cursor-pointer">
                        <OfferSideBarMenu 
                            label={OFFER_CANDIDATES} 
                            badge={offer != null ? offer.totalNewClaims : 0} 
                            onClick={() => setSelectedTab(Tab.CANDIDATES)} 
                            selected={selectedTab === Tab.CANDIDATES}
                        />
                        
                        <OfferSideBarMenu 
                            label={OFFER_DETAILS}
                            badge={0} 
                            onClick={() => setSelectedTab(Tab.DETAILS)} 
                            selected={selectedTab === Tab.DETAILS}
                        />
                    </div>
                </div>
            </div>
            {/* Content */}
            <div className="flex flex-1 w-full overflow-x-hidden">
                {selectedTab === Tab.CANDIDATES && (
                    // Candidates list
                    <OfferClaimsList />
                )}
                {selectedTab === Tab.DETAILS && (
                    // Offer info
                    <OfferInfoCard offer={offer!} />
                )}
            </div>
        </div>
    )
}
