import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store/types";
import { EMPTY_OFFER_STATE_TITLE, OFFER_LIST_TITLE } from "locales/offers";
import { CircularProgress } from "@mui/material";
import { Offer } from "types/offers";
import { FilterSelection, FiltersRow } from "./FilterRow";
import { setAppliedFilter } from "store/actions/offers";
import { OfferListCard } from "./OfferListCard";

interface OfferListingComponentProps {
    offers: Offer[];
    loading: boolean;
    selectOffer: (offerId: number) => void;
}

export const OFFER_STATUS_FILTER_SELECTIONS: FilterSelection[] = [
    {
        id: 'all',
        label: "Todos",
        mappingStatus: 'ALL'
    },
    {
        id: 'activeOffers',
        label: "Activas",
        mappingStatus: 'PUBLISHED'
    },
    {
        id: 'closedOffers',
        label: "Cerradas",
        mappingStatus: 'CLOSED'
    }
]

export const OfferListingComponent: React.FC<OfferListingComponentProps> = ({
    offers,
    loading,
    selectOffer
}) => {
    const { appliedFilter } = useSelector((state: RootState) => state.offerList)
    const dispatch = useDispatch();
    return (
        <div className="flex flex-1 pt-[24px] overflow-y-auto no-scrollbar justify-center w-full" >
            <div className="flex flex-col w-full h-full px-medium  pb-[24px] max-w-[1024px]">
                <div className="flex w-full justify-between items-center mb-large flex-wrap space-x-small">
                    <div
                        className="flex space-x-small items-center mr-small"
                    >
                        <p
                            className="heading-md text-ellipsis text-nowrap"
                        >
                            {OFFER_LIST_TITLE}
                        </p>
                        <p
                            className="body-large text-Text-Subtle "
                        >
                            ({offers.length})
                        </p>
                    </div>
                </div>
                <div className="flex space-x-tiny w-full items-center pb-large flex-wrap space-y-tiny">
                    <FiltersRow
                        selections={OFFER_STATUS_FILTER_SELECTIONS}
                        appliedFilter={appliedFilter}
                        setFilter={(filter: string) => {
                            console.log('setAppliedFilter', filter)
                            dispatch(setAppliedFilter(filter))
                        }}
                    />
                </div>
                <div
                    className="flex w-full space-y-small justify-center"
                >
                    {loading ? (
                        <div className="flex flex-1 justify-center items-center h-full" >
                            <CircularProgress />
                        </div>
                    ) : 
                        offers.length === 0 ? 
                        (
                            <div className="p-medium flex flex-col text-center items-center"
                            >
                                <p className="heading-md text-Text-Default mb-small">
                                    {EMPTY_OFFER_STATE_TITLE}
                                </p>
                            </div>
                        ) :
                            <div className="flex flex-col flex-1 space-y-small">
                                <div className="flex flex-col w-full" >
                                    <div className={"flex flex-col  space-y-small"}>
                                        {
                                            offers.map((offer, index) => (
                                                <OfferListCard
                                                    key={index}
                                                    offer={offer}
                                                    onClick={() => {
                                                        selectOffer(offer.id)
                                                    }}
                                                />
                                            ))
                                        }
                                    </div>    
                                </div>


                                <div
                                    style={{
                                        height: '24px',
                                    }}
                                >
                                </div>
                            </div>

                    }
                </div>
            </div>
        </div>
    )
}