import LivoIcon from "assets/LivoLogo";
import { FACILITY_EXPERIENCE, IN, LIVO_EXPERIENCE, PROFESSIONAL_DATA_TITLE, REVIEWS_LABEL, SINGLE_REVIEW_LABEL, TOTAL_REVIEWS_TITLE } from "locales/professionalClaim";
import React, { useRef, useState, useEffect } from "react";
import { ProfessionalProfile, ProfessionalReviewInfo } from "types/claims";

interface ProfessionalLivoReviewsProps {
    review: ProfessionalReviewInfo;
    noPadding: boolean;
}

export const ProfessionalLivoReviews: React.FC<ProfessionalLivoReviewsProps> = ({
    review,
    noPadding = false
}) => {
    const [expanded, setExpanded] = useState(false);
    const [height, setHeight] = useState("0px");
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (expanded) {
            setHeight(`${contentRef.current?.scrollHeight}px`);
        } else {
            setHeight("0px");
        }
    }, [expanded]);

    return (
        <div className={`flex flex-col w-full ${noPadding ? '' : 'p-medium'}`}>
            <div className="flex flex-row space-x-small mb-large">
                <LivoIcon name='star' size={24} color='#ACBBCB' />
                <p className="body-regular">
                    {LIVO_EXPERIENCE}
                </p>
            </div>
            <div className="flex flex-row w-full py-medium px-small rounded-[8px] bg-white ring-solid ring-[1px] ring-Divider-Subtle items-center space-x-small mb-large">
                <div className="flex flex-col w-full">
                    <p className="subtitle-regular">
                        {TOTAL_REVIEWS_TITLE}
                    </p>
                    <p className="body-regular text-Text-Subtle">
                        {IN} {review.totalReviews} {review.totalReviews > 1 ? REVIEWS_LABEL : SINGLE_REVIEW_LABEL}
                    </p>
                </div>
                <div className="flex flex-row space-x-small items-center">
                    <p className="heading-sm">
                        {review.averageRating.toFixed(1)}
                    </p>
                    <LivoIcon name='star-filled' size={24} color='#FF911C' />
                </div>
            </div>
            <div
                onClick={() => setExpanded(!expanded)}
                className="flex flex-row items-center space-x-small mb-small cursor-pointer"
            >
                <p className="action-regular text-Action-Primary">
                    {expanded ? 'Ver menos' : 'Ver más'}
                </p>
                <LivoIcon name={expanded ? 'chevron-up' : 'chevron-down'} size={24} color='#149EF2' />
            </div>
            <div
                ref={contentRef}
                style={{ maxHeight: height, transition: "max-height 0.3s ease" }}
                className="overflow-y-hidden"
            >
                <div className="flex flex-col w-full space-y-small">
                    {review.reviews.map((review, index) => (
                        <div key={index} className="flex flex-row w-full space-x-small">
                            <div className="flex flex-col w-full">
                                <p className="subtitle-regular">
                                    {review.facilityName}
                                </p>
                                <p className="body-regular text-Text-Subtle">
                                    {review.month} {review.year}
                                </p>
                            </div>
                            <div className="flex flex-row space-x-tiny items-center">
                                <p className="subtitle-regular">
                                    {review.review.generalRating.toFixed(1)}
                                </p>
                                <LivoIcon name='star-filled' size={16} color='#FF911C' />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
