import { TagLabel } from "components/common/TagLabel";

interface OfferStatusTagPros {
    status: 'PUBLISHED' | 'CLOSED'
}

export const OfferStatusTag: React.FC<OfferStatusTagPros> = ({ status }) => {
    const offerStatusStyling = {
        'PUBLISHED': '#E0EFFF',
        'CLOSED': '#EEEFF3'
    }
    const offerStatusText = {
        'PUBLISHED': 'Activa',
        'CLOSED': 'Cerrada'
    }

    return (
        <TagLabel text={offerStatusText[status]} backgroundColor={offerStatusStyling[status]} />
    )
}