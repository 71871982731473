import { useEffect, useState } from "react"
import moment from "moment"
import { Shift } from "types/shifts"
import { ShiftPublicationRequest, fetchPublishShiftConfiguration } from "services/publish-shift"
import { PublishShiftConfigurationDTO, RecurrencyOptionsEnum } from "types/publish-shift"
import { useDispatch } from "react-redux"
import { setDateSelected } from "store/actions"
import {  ShiftConfiguration } from "components/publishShift/BaseShiftForm"
import { ShiftUpdateRequest, updateShiftRequest } from "services/shifts-calendar"
import { ApiApplicationError } from "services/api"
import  PublishEditModal  from "components/publishShift/PublishEditModal"
import { EditRecurrentShiftModal } from "./EditRecurrentShiftModal"

interface EditShiftModalProps {
    onClose: () => void;
    isOpen: boolean;
    reloadData: () => void;
    shiftDetails: Shift | null;
    bulkOperation?: RecurrencyOptionsEnum
}

export const EditShiftModal: React.FC<EditShiftModalProps> = ({
    onClose,
    isOpen,
    reloadData,
    shiftDetails,
}) => {
    // select the the start time according to shift time in day

    const dispatch = useDispatch()

    const shiftDetailsConfig = shiftDetails ? {
        ...shiftDetails,
        recurrentDates:[moment(shiftDetails.startTime).format('YYYY-MM-DD')],
        compensationOptions: shiftDetails?.compensationOptions.map((option) => option.value) || []
    } : null;


    return (
        <div>
            <PublishEditModal
                onSubmit={(shiftUpdateRequest: ShiftPublicationRequest) => {
                    return updateShiftRequest(shiftDetails!!.id, {
                        ...shiftUpdateRequest
                    } as ShiftUpdateRequest).then(() => {
                        onClose()
                        if (moment(moment(shiftUpdateRequest.startTime)).isSame(shiftDetails!!.date, 'day')) {
                        } else {
                            dispatch(setDateSelected(moment(shiftUpdateRequest.startTime).format('YYYY-MM-DD')))
                        }
                        reloadData()
                    })
                }}
                shiftDetails={
                    {
                        ...shiftDetailsConfig,
                    } as ShiftConfiguration}
                onClose={onClose}
                isOpen={isOpen}
                isEditing={true}
            />
        </div>

    )
}