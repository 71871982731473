export const PROFESSIONAL_PROFILE_TITLE = 'Detalles de la cobertura';
export const PROFESSIONAL_DETAILS_TITLE = 'Datos del profesional';
export const EXPERIENCE_IN_FACILITY_TITLE = 'Experiencia en el centro';
export const EXPERIENCE_IN_LIVO_TITLE = 'Experiencia en Livo';
export const REPLACEMENT_REASON_TITLE = 'Motivo de reemplazo';
export const CANCEL_CLAIM_REASON_TITLE = 'Motivo de cancelación';
export const COMPENSATION_TYPE_TITLE = 'Tipo de solicitud';
export const NO_REVIEWS_TITLE = 'Sin calificar';
export const TOTAL_REVIEWS_TITLE = 'Total';
export const TOTAL_REVIEWS_SUBTITLE = (num: number) => `en ${num} valoraciones`;
export const TOTAL_SHIFTS_SUBTITLE = (num: number) => `${num} turnos`;
export const TOTAL_SHIFTS_SUBTITLE_IN_FACILITY = 'en tu hospital';
export const SKILLS_LABEL = 'Competencias';
export const SKILLS_SECTION_LABEL = 'Experiencia';
export const CATEGORY_SECTION_LABEL = 'Puesto de trabajo';
export const PERSONAL_DATA_LABEL = 'Datos personales';
export const PHONE_LABEL = 'Número de teléfono';
export const NATIONAL_ID_LABEL = "ID"
export const PROFESSIONAL_AI_CV_TITLE = 'Resumen IA (Inteligencia Artificial)';
export const EMAIL_LABEL = 'Correo electrónico';
export const MISSING_EMAIL_LABEL = 'Campo vacío';