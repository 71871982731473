import { CategoryTag } from "components/common/CategoryTag";
import { TagLabel } from "components/common/TagLabel";
import { ProfilePicture } from "components/shiftDetails/ProfilePicture";
import { ShiftModalityEnum } from "types";
import { OfferClaim, OfferClaimStatus } from "types/offers";
import { OfferClaimStatusTag } from "./OfferStatusTag";
import LivoIcon from "assets/LivoLogo";
import { ProfessionalRatingStar } from "components/common/ProfessionalRatingStar";
import { SkillTagsCompact } from "./SkillTagsCompact";

interface OfferClaimCardProps {
    claim: OfferClaim;
    isSelected: boolean;
    selectClaim: (claimId: OfferClaim) => void;
}

export const OfferClaimCard: React.FC<OfferClaimCardProps> = ({
    claim,
    isSelected,
    selectClaim
}) => {
    return (
        <div
            onClick={() => selectClaim(claim)}
            className={`flex flex-row  py-small px-small items-center ${isSelected ? 'bg-Action-Secondary text-Text-Inverse' : 'bg-white'} rounded-[16px] relative cursor-pointer`}
            style={{
                minWidth: '200px',
            }}
        >
            <div className="flex-1 grid grid-cols-10 items-center justify-between space-x-small w-full">
                <div className="flex col-span-2 text-left">
                    <div
                        className="flex flex-row items-center space-x-small"
                    >
                        <ProfilePicture profilePictureUrl={claim.professionalProfile.profilePictureUrl} size={48} modality={ShiftModalityEnum.EXTERNAL} />
                        
                        <p className="subtitle-regular">{claim.professionalProfile.fullName}</p>
                    </div>
                </div>
                <div className="flex col-span-2 items-end flex-wrap gap-1 ml-5">
                    <SkillTagsCompact skills={claim.professionalProfile.skills} flexWrap={true} />
                </div>
                
                <div className="flex col-span-3 w-15 text-left">
                    <div className="h-8 text-[#2c3038] text-s font-normal font-['Roboto'] leading-none line-clamp-2 overflow-hidden">
                        {claim.professionalProfile.cvSummary}
                    </div>    
                </div>

                {claim.professionalProfile.professionalReview && (
                    <div className="flex col-span-2 items-center flex-row justify-start w-full space-x-tiny">
                        <div className="flex flex-1 w-full justify-center items-center">
                            <ProfessionalRatingStar 
                                professionalReview={claim.professionalProfile.professionalReview}
                                textClassName={isSelected ? "text-Text-Inverse" : undefined}
                            />
                        </div>
                    </div>
                )}

                <div className="flex col-span-1 items-center flex-row justify-start w-full space-x-tiny">
                    <div className="flex flex-1 w-full justify-center items-center">
                        <OfferClaimStatusTag status={claim.status} />
                    </div>
                </div>
            </div>
            <div className="w-[20px]">
                {claim.newClaim && (
                    <div className="flex items-center justify-center ml-tiny">
                        <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
                    </div>
                )}
            </div>
        </div>
    )
}