import { CategoryTag } from "components/common/CategoryTag";
import { NotificationsBadge } from "components/common/NotificationsBadge";
import { TagLabel } from "components/common/TagLabel";
import { Offer } from "types/offers";
import { OfferStatusTag } from "./OfferStatusTag";

interface OfferListCardProps {
    offer: Offer;
    onClick?: () => void;
}

export const OfferListCard: React.FC<OfferListCardProps> = ({ 
    offer,
    onClick
}) => {
    return (
        <div className="w-full">
            <div className="relative group">
                <div
                    onClick={onClick}
                    className={`flex flex-row bg-white rounded-[16px] relative ${onClick ? 'cursor-pointer' : ''} overflow-hidden pr-small`}
                    style={{
                        minWidth: "200px",
                        maxWidth: "1280px",
                    }}
                >
                    <div className={`w-[8px] bg-[#987ed4] flex overflow-hidden`}></div>

                    <div className="flex flex-1 items-center justify-between p-small py-large space-x-small w-full">
                        <div className="flex flex-1 text-left w-[180px]">
                            <div className="flex flex-row w-full space-x-small">
                                <div className="flex items-center flex-row justify-start space-x-tiny">
                                    <CategoryTag text={offer.category.acronym} />
                                    <div className="group relative">
                                        <h1 className="subtitle-regular truncate max-w-[160px]">
                                            {offer.skills.join(', ')}
                                        </h1>
                                        {offer.skills.length > 0 && (
                                            <div className="fixed transform -translate-x-2 mt-2 invisible group-hover:visible bg-[#2C3038] text-white py-1 px-2 rounded z-50">
                                                <div className="flex flex-col space-y-2 text-left text-[11px]">
                                                    {offer.skills.map((skill, index) => (
                                                        <span key={index}>{skill}</span>
                                                    ))}
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-1 items-center flex-row justify-start w-full space-x-tiny">
                            <div className="flex flex-1 w-full justify-start items-center">
                                <p>{offer.duration}</p>
                            </div>
                        </div>
                        <div className="flex flex-1 items-center flex-row justify-start w-full space-x-tiny">
                            <div className="flex flex-1 w-full justify-start items-center">
                                <p className={`${offer.compensation.defined ? "text-Green-700" : "text-Text-Subtle"}`}>
                                    {offer.compensation.displayText}
                                </p>
                            </div>
                        </div>
                        <div className="flex flex-1 items-center flex-row justify-start w-full space-x-tiny">
                            <div className="flex flex-1 w-full justify-center items-center">
                                <p>
                                    <OfferStatusTag status={offer.status} />
                                </p>
                            </div>
                        </div>
                        <div className="w-[20px]">
                            {offer.totalNewClaims > 0 && (
                                <NotificationsBadge notifications={offer.totalNewClaims} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};