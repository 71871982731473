import LivoIcon from "assets/LivoLogo";


interface TagLabelProps {
    text: string;
    icon?: string;
    color?: string;
    iconColor?: string;
    style?: any;
    backgroundColor?: string;
    onRemove?: () => void;
    closeColor?: string;
}

export const TagLabel: React.FC<TagLabelProps> = ({ 
    text,
    icon,
    color,
    iconColor,
    backgroundColor='#EEEFF3',
    closeColor='#707A91',
    onRemove,
    style,

}) => {
    return (
        <div
            className={`flex flex-row items-center px-[6px] py-[2px] rounded-[4px] space-x-tiny ${style}`}
            style={{
                backgroundColor: backgroundColor,
                color: color
            }}
            >
            {
                icon ? <LivoIcon name={icon} size={16} color={iconColor || '#707A91'} /> : null
            }
            <p className={`info-caption text-${color ? color : 'Text-Default'} text-nowrap`}>
                {text}
            </p>
            {
                onRemove ?
                <div
                    onClick={onRemove}
                    className="flex items-center justify-center cursor-pointer"
                >
                    <LivoIcon name="close" size={24} color={closeColor} />
                </div> : null
            }
        </div>
    );
}