import { DenarioProfessional } from "types/internal"

interface DenarioProfessionalModalProps {
    denarioProfessional: DenarioProfessional,
    onClose: () => void,
    onAcceptProfessionalData: () => void
}

export const DenarioProfessionalModal: React.FC<DenarioProfessionalModalProps> = ({ 
    denarioProfessional,
    onClose,
    onAcceptProfessionalData
}) => {
    return (
        <div className="fixed inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white rounded-lg p-6 w-96 relative z-[51]">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-xl font-bold">DNI/NIE registrado  en Denario</h2>
                </div>
                <div className="mb-4">
                    <p className="mb-2 text-[#2c3038] text-base font-normal font-['Roboto'] leading-normal">Hemos encontrado un usuario con esta identificación en el sistema Denario:</p>
                    <div className="bg-gray-50 p-4 rounded-md">
                        <p className="font-medium">{denarioProfessional.firstName} {denarioProfessional.lastName}</p>
                        <p className="text-gray-600">DNI: {denarioProfessional.nationalId}</p>
                    </div>
                    <p className="mt-4 text-[#2c3038] text-base font-normal font-['Roboto'] leading-normal">¿Te gustaría actualizar el resto campos con la información del profesional y sincronizarlo con Denario?</p>
                </div>
                <div className="flex justify-between w-full">
                    <button
                        onClick={onClose}
                        className="w-[49%] px-4 py-2 rounded-full hover:bg-gray-50"
                    >
                        Volver
                    </button>
                    <button
                        className="w-[49%] px-4 py-2 bg-blue-600 text-white rounded-full hover:bg-blue-700"
                        onClick={onAcceptProfessionalData}
                    >
                        Actualizar datos
                    </button>
                </div>
            </div>
        </div>
    )
}