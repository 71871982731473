import { ProfessionalInitProfile, ProfessionalLegalProfile } from "types";

export const PAGE_TITLE = 'Documentación'
export const PROFESIONAL_INFORMATION_TITLE = 'Información del profesional';
export const SHIFT_INFORMATION_TITLE = 'Información del turno';
export const LEGAL_DOCUMENTATION_TITLE = 'Documentos legales';
export const UNCOMPLETE_DOCUMENTATION_DISCLAIMER = 'Estamos trabajando en completar la documentación de este profesional.';
export const PROFESSIONAL_PROFILE_TITLE = 'Perfil del profesional';
export const LEGAL_INFORMATION_TITLE = 'Información fiscal';
export const COPY_ALL_FIELDS = 'Copiar todo';
export const copyProfessionalFields = (ProfessionalInitProfile: ProfessionalInitProfile, professionalLegalProfile?: ProfessionalLegalProfile) => {
    return (
`Nombre: ${ProfessionalInitProfile.firstName || ''}
Apellidos: ${ProfessionalInitProfile.lastName || ''}
Número de teléfono: ${ProfessionalInitProfile.phoneNumber || ''}
Correo electrónico: ${ProfessionalInitProfile.email || ''}
Número de colegiado/a: ${ProfessionalInitProfile.licenseNumber || ''}
${professionalLegalProfile ?
`DNI: ${professionalLegalProfile.nationalId || ''}
Dirección: ${professionalLegalProfile.address || ''}
Número de cuenta: ${professionalLegalProfile.bankAccountNumber || ''}
Porcentaje de deducción: ${professionalLegalProfile.deductionPercentage+'%' || ''}
Número de la seguridad social: ${professionalLegalProfile.socialSecurityNumber || ''}
` : ''}`)
}
export const DOWNLOAD_ALL_FILES = 'Descargar todos';
export const DOWNLOAD_ALL_ERROR = 'Error al descargar los archivos';
export const COPY_FIELD_SUCCESS = 'Información copiada'
export const LICENSE_NUMBER_LABEL = 'Número de colegiado/a';
export const CURRICULUM_LABEL = 'Currículum';
export const IBAN_DOCUMENT_LABEL = 'Documento IBAN';
export const DEDUCTION_PERCENTAGE_DOCUMENT_LABEL = 'Permiso retención IRPF'
export const PROFILE_PICTURE_DOCUMENT_LABEL = 'Foto de perfil';
export const SOCIAL_SECURITY_NUMBER_LABEL = 'Número de la seguridad social';
export const NUSS = 'NUSS';
export const DEDUCTION_PERCENTAGE_LABEL = 'Porcentaje de deducción';
export const NATIONAL_ID = 'DNI';
export const NON_CONVICTION_CERTIFICATE_LABEL = 'Ausencia de abuso a menores';
export const PAYMENT_RECEIPT_LABEL = 'Recibo de pago';
export const ADDRESS_LABEL = 'Dirección';
export const BANK_ACCOUNT_NUMBER_LABEL = 'Número de cuenta';
export const CERTIFICATE_LABEL = 'Título'
export const EXTRA_CERTIFICATE_1 = 'Extra Título 1'
export const EXTRA_CERTIFICATE_2 = 'Extra Título 2'
export const EXTRA_CERTIFICATE_3 = 'Extra Título 3'
export const CONTACT_INFORMATION_LABEL = 'Contacto';
export const EMAIL_LABEL = 'Correo electrónico';
export const PHONE_NUMBER_LABEL = 'Número de teléfono';
export const EXPIRED_DOCUMENT_LABEL = '(Documento caducado)';

// fields copied successfully

export const NATIONAL_ID_COPIED = 'DNI copiado con éxito';
export const ADDRESS_COPIED = 'Dirección copiada con éxito';
export const BANK_ACCOUNT_NUMBER_COPIED = 'Número de cuenta copiado con éxito';
export const SOCIAL_SECURITY_NUMBER_COPIED = 'Número de la seguridad social copiado con éxito';
export const DEDUCTION_PERCENTAGE_COPIED = 'Porcentaje de deducción copiado con éxito';
export const LICENSE_NUMBER_COPIED = 'Número de colegiado/a copiado con éxito';
export const EMAIL_COPIED = 'Correo electrónico copiado con éxito';
export const PHONE_NUMBER_COPIED = 'Número de teléfono copiado con éxito';





export const START_TIME_LABEL = 'Hora de inicio';
export const FINISH_TIME_LABEL = 'Hora de fin';
export const DATE_LABEL = 'Fecha';
export const TOTAL_PAY_LABEL = 'Pago total';
export const HOUR_RATE_LABEL = 'Pago por hora';
export const CAPACITY_LABEL = 'Número de puestos';
export const CONFIRM_BUTTON_LABEL = 'Confirmar';
export const CONFIRMATION_SUCCESS_MESSAGE = 'Turno confirmado con éxito';
export const CONFIRMATION_ERROR_MESSAGE = 'Ha ocurrido un error procesando su solicitud';
export const UNAVAILABLE_SHIFT_MESSAGE = 'No hemos podido cargar el turno'
export const UNAVAILABLE_LEGAL_DOCUMENTATION = ' La documentación legal del profesional aún no está disponible. \n' +
    'Podrá encontrarla en esta sección cuando el profesional nos la envíe.';
export const UNAVAILABLE_LEGAL_INFORMATION = 'Estamos solicitando al profesional su información legal. \n' +
    'Cuando nos la envíe, podrá encontrarla en esta sección.';
export const CONTACT_LABEL = "Contacto";
export const CONTACT_INFO = "Si alguno de los documentos no es correcto o necesita más información, ponte en contacto con el equipo de soporte a través de";
export const ERROR_CONTACT_INFO = "Error cargando la información de contacto";
export const PROFESSIONALS_LABEL = 'Profesionales';
export const REASON_LABEL = 'Motivo';
export const REASONS_LABEL = 'Motivos';
export const CONFIRMED_LABEL = 'Confirmados';
export const NO_REVIEWS_LABEL = 'Sin reseñas';
export const PENDING_TO_FILL_CAPACITY_ITEM = 'Puesto pendiente...';
export const ADD_CAPACITY_LABEL = 'Añadir puesto';
export const EDIT_SHIFT_LABEL = 'Editar turno';
export const DUPLICATE_SHIFT_LABEL = 'Duplicar turno';
export const DELETE_SHIFT_LABEL = 'Eliminar turno';
export const ADD_CAPACITY_MODAL_TITLE = '¿Cuántos puestos quieres agregar al turno?';
export const ADD_CAPACITY_MODAL_GO_BACK = 'Regresar';
export const ADD_CAPACITY_MODAL_BUTTON = 'Agregar';
export const CANCEL_SHIFT_MODAL_TITLE = '¿Por qué quieres cancelar el turno?'
export const CANCEL_SHIFT_MODAL_TITLE_MULTIPLE = '¿Por qué quieres cancelar los turnos?'
export const CANCEL_SHIFT_RECURRENT_TITLE = 'Cancelar turno'
export const CANCEL_SHIFT_RECURRENT_BUTTON = 'Cancelar turno'
export const CANCEL_SHIFT_MODAL_GO_BACK = 'Regresar'
export const CANCEL_SHIFT_MODAL_BUTTON = 'Eliminar'
export const DECREASE_CAPACITY_MODAL_TITLE = '¿Por qué quieres eliminar este puesto?'
export const PENDING_CLAIMS_TITLE = 'Solicitudes'
export const REJECT_PROFESSIONAL_MODAL_TITLE = '¿Por qué quieres rechazar esta solicitud?';
export const REJECT_PROFESSIONAL_MODAL_BUTTON = 'Rechazar';
export const CANCELLATION_REQUEST = 'Solicitud de cancelación';
export const REJECT_REQUEST_LABEL = 'Rechazar';
export const ACCEPT_REQUEST_LABEL = 'Aceptar';
export const CANCELLATION_REQUEST_REASON = 'Motivo de cancelación';
export const COMPENSATION_OPTION_TYPE_LABEL = 'Tipo de solicitud';
export const SLOT_REASON_LABEL = 'Motivo de reemplazo';
export const SLOT_REASON_COMMENT = 'Comentario adicional';
export const SLOT_REASON_UPDATE_SUCCESS = 'Motivo de reemplazo actualizado correctamente';
export const SLOT_REASON_ACCEPT = 'Aceptar solicitud';
export const SLOT_REASON_UPDATE_ERROR = 'Error al actualizar el motivo de reemplazo';
export const UPDATE_SLOT_REASON_BUTTON = 'Cambiar motivo';
export const CANCEL_SLOT_REASON_BUTTON = 'Cancelar';
export const HOLIDAY_TAG = 'Festivo';
export const EMPTY_SLOT_REASON = 'Sin motivo';
export const CATEGORIES_LABEL = 'Categorías';
export const PUBLISHED_ON_DAY_MONTH_YEAR = (day: number, month: string, year: number) => `Publicado el ${day} de ${month} de ${year}`;
export const PUBLISHED_DAYS_AGO = (diffDays: number, diffHours: number) => `Publicado hace ${diffDays} día(s)${diffHours > 0 ? ` y ${diffHours} hora(s)` : ''}`;
export const PUBLISHED_HOURS_AGO = (diffHours: number) => `Publicado hace ${diffHours} hora(s)`;



