import { Category } from "../../types/shifts";
import LivoIcon from "../../assets/LivoLogo";
import { CategoryTag } from "../../components/common/CategoryTag";

interface LargeCardHeaderProps {
    title: string;
    recurrent: boolean;
    style?: any
    category?: Category;
}

export const LargeCardHeader: React.FC<LargeCardHeaderProps> = ({
    title,
    recurrent,
    style,
    category,
}) => {
    return (
        <div
            className="flex flex-row w-full items-center justify-start space-x-tiny"
        >
            {category ? <CategoryTag
                text={category.acronym} /> : null}
            {recurrent ? <LivoIcon name='repeat' size={16} color='#ACBBCB' /> : null}
            <h1 className="subtitle-regular truncate"
                style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}
            >
                {title}
            </h1>
        </div>

    )
}