
interface OfferClaimButtonProps {
    label: string;
    color: string;
    backgroundColor: string;
    onClick: () => void;
}

export const OfferClaimButton: React.FC<OfferClaimButtonProps> = ({ label, color, backgroundColor, onClick }) => {
    return (
        <button
            style={{ backgroundColor }}
            className="px-3 py-2 rounded justify-start items-center gap-1 flex"
            onClick={onClick}
        >
            <div
                style={{ color }}
                className="text-base font-medium font-['Roboto'] leading-none tracking-tight"
            >
                {label}
            </div>
        </button>
    )
}