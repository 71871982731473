import { TagComponent } from "components/common/TagComponent";

interface FiltersRowPros {
    selections: FilterSelection[]
    appliedFilter: string;
    setFilter: (filters: string) => void;
}

export interface FilterSelection {
    id: string,
    label: string,
    mappingStatus: string
}

export const FiltersRow: React.FC<FiltersRowPros> = ({
    selections,
    appliedFilter,
    setFilter
}) => {
    return (
        <div
            className="flex space-x-tiny w-full items-center pb-large flex-wrap"
        >
            {
                selections.map((filter, index) => (
                    <TagComponent
                        key={index}
                        label={filter.label}
                        onClick={() => {
                            setFilter(filter.id)
                        }}
                        isSelected={appliedFilter == filter.id}
                    />
                ))
            }
        </div>
    )
}