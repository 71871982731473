import CircularProgress from "@mui/material/CircularProgress";
import { FilterSelection, FiltersRow } from "components/offerlist/FilterRow";
import { EMPTY_OFFER_CLAIM_TITLE, OFFER_CANDIDATES } from "locales/offers";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchOfferClaims } from "services/offers";
import { RootState } from "store/types";
import { OfferClaim } from "types/offers";
import { OfferClaimCard } from "./OfferClaimCard";
import { OfferClaimDetail } from "./OfferClaimDetail";
import { OfferClaimCardCompact } from "./OfferClaimCardCompact";

interface OfferCandidateListProps {
    
}

export const OFFER_CANDIDATES_FILTER_SELECTIONS: FilterSelection[] = [
    {
        id: 'all',
        label: "Todos",
        mappingStatus: 'ALL'
    },
    {
        id: 'activeClaims',
        label: "Activas",
        mappingStatus: 'ACTIVE'
    },
    {
        id: 'discardedClaims',
        label: "Descartados",
        mappingStatus: 'CLOSED'
    }
]

export const OfferClaimsList: React.FC<OfferCandidateListProps> = ({
    
}) => {
    const [selectedFilter, setSelectedFilter] = useState('all')
    const {selectedOfferId} = useSelector((state: RootState) => state.offerList)
    const [claims, setClaims] = useState<OfferClaim[]>([])
    const [totalClaims, setTotalClaims] = useState(0)
    const [loadingClaims, setLoadingCandidates] = useState(false)
    const [selectedOfferClaim, setSelectedOfferClaim] = useState<OfferClaim | null>(null)

    const loadData = async () => {
        if (!selectedOfferId) {
            return
        }
        const filterStatus = OFFER_CANDIDATES_FILTER_SELECTIONS.find((filter) => filter.id === selectedFilter)?.mappingStatus
        return fetchOfferClaims(
            selectedOfferId,
            filterStatus
        ).then((response) => {
            setClaims(response.rows)
            setTotalClaims(response.total)
            if (selectedOfferClaim) {
                const updatedClaim = response.rows.find((claim) => claim.id === selectedOfferClaim.id) || null;
                setSelectedOfferClaim(updatedClaim);
            }
        }).catch((error) => {
            console.error(error)
        })
    }

    const loadCandidates = async () => {
        setLoadingCandidates(true)
        await loadData().then(() => {
            setLoadingCandidates(false)
        }).catch((error) => {
            console.error(error)
        })
    }

    useEffect(() => {
        loadCandidates()
    }, [selectedOfferId, selectedFilter])

    return (
        <div className="flex flex-1 justify-center w-full">
            <div className={`flex pt-6 px-5 ${selectedOfferClaim != null ? 'w-[400px]' : 'flex-1'} flex-col overflow-y-auto no-scrollbar h-full px-medium pb-[24px] max-w-[1024px]`}>
                <div className="flex w-full justify-between items-center mb-large flex-wrap space-x-small">
                    <div className="flex space-x-small items-center mr-small">
                    <p className="heading-md text-ellipsis text-nowrap">
                        {OFFER_CANDIDATES}
                    </p>
                    {totalClaims > 0 && (
                        <p className="body-large text-Text-Subtle">
                            ({totalClaims})
                        </p>
                    )}
                    </div>
                </div>
                <div className="flex space-x-tiny w-full items-center pb-large flex-wrap space-y-tiny">
                    <FiltersRow
                    selections={OFFER_CANDIDATES_FILTER_SELECTIONS}
                    appliedFilter={selectedFilter}
                    setFilter={(filter: string) => {
                        setSelectedFilter(filter);
                    }}
                    />
                </div>
                <div
                    className="flex w-full space-y-small justify-center"
                >
                    {loadingClaims ? (
                        <div className="flex flex-1 justify-center items-center h-full" >
                            <CircularProgress />
                        </div>
                    ) : 
                        claims.length === 0 ? 
                        (
                            <div className="p-medium flex flex-col text-center items-center"
                            >
                                <p className="heading-md text-Text-Default mb-small">
                                    {EMPTY_OFFER_CLAIM_TITLE}
                                </p>
                            </div>
                        ) :
                            <div className="flex flex-col flex-1 space-y-small">
                                <div className="flex flex-col w-full" >
                                    <div className={"flex flex-col  space-y-small"}>
                                        {selectedOfferClaim === null && 
                                            claims.map((claim, index) => (
                                                <OfferClaimCard
                                                    key={index}
                                                    claim={claim}
                                                    isSelected={false}
                                                    selectClaim={
                                                        (claim) => {
                                                            setSelectedOfferClaim(claim)
                                                        }
                                                    }
                                                />
                                            ))
                                        }
                                        {selectedOfferClaim != null && (
                                            claims.map((claim, index) => (
                                                <OfferClaimCardCompact
                                                    key={index}
                                                    claim={claim}
                                                    isSelected={selectedOfferClaim.id === claim.id}
                                                    selectClaim={
                                                        (claim) => {
                                                            setSelectedOfferClaim(claim)
                                                        }
                                                    }
                                                />
                                            ))
                                        )}
                                        
                                    </div>    
                                </div>


                                <div
                                    style={{
                                        height: '24px',
                                    }}
                                >
                                </div>
                            </div>

                    }
                </div>
            </div>
            {selectedOfferClaim != null && (
                <OfferClaimDetail
                    selectedOfferClaim={selectedOfferClaim}
                    onClose={() => setSelectedOfferClaim(null)}
                    refreshClaims={loadCandidates}
                />
            )}
        </div>
    )
}