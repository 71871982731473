import LivoIcon from "assets/LivoLogo";
import { ProfessionalLivoReviews } from "components/shiftDetails/professionalClaim/ProfessionalLivoReviews";
import { ProfilePicture } from "components/shiftDetails/ProfilePicture";
import { CALL_TO_DISCLOSE_DETAILS } from "locales/offers";
import { COMPLETED_SHIFTS, DOWNLOAD_CV_LABEL, EMAIL_LABEL, EXPERIENCE_LABEL, FACILITY_EXPERIENCE, IN, LICECNSE_NUMBER_LABEL, PROFESSIONAL_DATA_TITLE, PROFESSIONAL_LIVO_CV_TITLE, TELEPHONE_LABEL, VIEW_CV_LABEL, VIEW_LIVO_CV_LABEL } from "locales/professionalClaim";
import { PROFESSIONAL_AI_CV_TITLE } from "locales/professionals/profile";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import { ShiftModalityEnum } from "types";
import { OfferClaimStatus, OfferProfessionalProfile } from "types/offers";
import { OfferClaimStatusTag } from "./OfferStatusTag";
import { CVType } from "types/claims";
import { LivoCVModal } from "components/curriculum/LivoCVModal";
import { useLivoCVModal } from "components/curriculum/useLivoCVModal";

interface OfferProfessionalProfileProps {
    profile: OfferProfessionalProfile,
    detailsDisclosed: boolean,
    callToActionText: string | null,
    status: OfferClaimStatus
}

export const OfferProfessionalProfileSection: React.FC<OfferProfessionalProfileProps> = ({ 
    profile,
    detailsDisclosed,
    callToActionText,
    status
 }) => {
    const account = useSelector((state: RootState) => state.account);
    const livoCVModal = useLivoCVModal(profile.id);

    const hasLivoCV = profile.availableCVTypes?.includes(CVType.LIVO_CV);
    const hasPdfUpload = profile.availableCVTypes?.includes(CVType.PDF_UPLOAD);
    
    return (
        <div className="flex flex-col w-full p-4">
            {/* Profile Header */}
            <div className="flex flex-col items-center space-y-2">
                <ProfilePicture 
                    profilePictureUrl={profile?.profilePictureUrl} 
                    modality={ShiftModalityEnum.EXTERNAL} 
                    size={64} 
                />
                <p className="heading-md text-Text-Default">
                    {profile.fullName}
                </p>
            </div>

            <div className="flex flex-row items-center space-x-2 my-1">
                <OfferClaimStatusTag status={status} />
            </div>

            {/* Main Content */}
            <div className="flex flex-col divide-y divide-Divider-Default">
                {/* Professional Info Section */}
                <div className="flex flex-col mb-6">
                    <div className="flex items-center space-x-2 pt-3 mb-5">
                        <LivoIcon name='id-badge-2' size={24} color='#ACBBCB' />
                        <p className="text-[#2c3038] text-base font-roboto">
                            {PROFESSIONAL_DATA_TITLE}
                        </p>
                    </div>

                    <div className={`flex flex-col ${detailsDisclosed ? 'bg-[#e0efff] p-4 rounded-lg' : ''}`}>
                        {detailsDisclosed && (
                            <>
                                <div className="flex space-x-2 mb-2">
                                    <p className="body-regular text-[#707A91]">{TELEPHONE_LABEL}:</p>
                                    <p className="subtitle-regular text-Text-Default">{profile.phoneNumber}</p>
                                </div>
                                <div className="flex space-x-2 mb-2">
                                    <p className="body-regular text-[#707A91]">{EMAIL_LABEL}:</p>
                                    <p className="subtitle-regular text-Text-Default">{profile.email}</p>
                                </div>
                            </>
                        )}

                        <div className="flex space-x-2 mb-2">
                            <p className="body-regular text-[#707A91]">{LICECNSE_NUMBER_LABEL}:</p>
                            <p className="subtitle-regular text-Text-Default">{profile.licenseNumber}</p>
                        </div>

                        <div className="space-x-2">
                            <span className="subtitle-regular text-[#707A91]">
                                {EXPERIENCE_LABEL}:
                            </span>
                            {hasLivoCV && (
                                <button 
                                    className="subtitle-regular body-regular text-[#149EF2] cursor-pointer hover:underline"
                                    onClick={() => livoCVModal.showModal()}
                                >
                                    {VIEW_LIVO_CV_LABEL}
                                </button>
                            )}
                            {hasLivoCV && hasPdfUpload && (
                                <span className="text-[#707A91] mx-8">|</span>
                            )}
                            <a className="subtitle-regular text-[#149EF2] hover:underline" href={profile.professionalCV} target="_blank">
                                {DOWNLOAD_CV_LABEL}
                            </a>
                        </div>
                        
                        {callToActionText && (
                            <p className="text-[#707a91] text-xs font-roboto mt-4">
                                {CALL_TO_DISCLOSE_DETAILS}
                            </p>
                        )}
                    </div>
                    
                    {/* AI Summary */}
                    {profile.cvSummary && (
                        <div className="space-y-2 mt-6">
                            <div className="flex items-center space-x-2">
                                <LivoIcon name='sparkles' size={24} color='#ACBBCB' />
                                <p className="text-[#2c3038] text-base font-roboto">{PROFESSIONAL_AI_CV_TITLE}</p>
                            </div>
                            <p className="text-[#707a91] text-base font-roboto">
                                {profile.cvSummary}
                            </p>
                        </div>
                    )}
                </div>

                {/* Facility Experience */}
                <div className="flex flex-col mb-6 pt-5">
                    <div className="flex items-center space-x-2 mb-5">
                        <LivoIcon name='report-medical' size={24} color='#ACBBCB' />
                        <p className="body-regular">{FACILITY_EXPERIENCE}</p>
                    </div>
                    <div className="flex items-center p-3 rounded-lg bg-white ring-1 ring-Divider-Subtle">
                        <div className="flex-1">
                            <p className="subtitle-regular">{COMPLETED_SHIFTS}</p>
                            {account.accountInfo && 
                                <p className="body-regular text-Text-Subtle">
                                    {IN} {account.accountInfo.facility.name}
                                </p>
                            }
                        </div>
                        <p className="heading-sm mr-3">{profile.totalShiftsInFacility}</p>
                    </div>
                </div>

                {/* Reviews */}
                {profile.professionalReview?.averageRating && (
                    <div className="pt-5 mb-6">
                        <ProfessionalLivoReviews 
                            review={profile.professionalReview}
                            noPadding={true}
                        />
                    </div>
                )}
            </div>

            {livoCVModal.livoCVDetails && (
                <LivoCVModal
                    fullScreen
                    style={{ width: '100%', maxWidth: '600px' }}
                    title={PROFESSIONAL_LIVO_CV_TITLE(profile.fullName)}
                    isOpen={livoCVModal.isOpen}
                    onClose={livoCVModal.hideModal}
                    livoCVDetails={livoCVModal.livoCVDetails}
                />
            )}
        </div>
    )
}