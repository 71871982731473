import LivoIcon from "assets/LivoLogo";
import { COMPENSATION_OPTION_LABEL } from "locales/publishShift";

interface CompensationOptionSelectorProps {
    compensationOptions: {
        value: string,
        displayText: string,
    }[];
    selectedCompensationOptions: string[];
    setCompensationOptions: (compensationOption: string[]) => void;
}

export const CompensationOptionSelector: React.FC<CompensationOptionSelectorProps> = ({
    selectedCompensationOptions,
    setCompensationOptions,
    compensationOptions
}) => {
    return (
        <div className="flex-1">
            <p
                className="info-caption mb-tiny"
            >
                {COMPENSATION_OPTION_LABEL}
            </p>
            <div
                className="flex flex-col border-[1px] border-solid border-Divider-Subtle rounded-[8px] px-small py-medium w-full space-y-medium self-start"
            >

                {compensationOptions.map((option, index) => {
                    return (
                        <button
                            key={index}
                            onClick={() => {
                                if (selectedCompensationOptions.includes(option.value)) {
                                    setCompensationOptions(selectedCompensationOptions.filter((value) => value !== option.value))
                                } else {
                                    setCompensationOptions([...selectedCompensationOptions, option.value])
                                }
                            }
                            }
                            className="flex flex-row justify-between items-center space-x-tiny"
                        >
                            <div
                                className="flex flex-row space-x-tiny items-center"

                            >
                                <p
                                    className="body-regular"
                                >
                                    {option.displayText}
                                </p>
                            </div>
                            {
                                <LivoIcon name={selectedCompensationOptions.includes(option.value) ? 'square-check-filled' : 'square'} size={18} color={selectedCompensationOptions.includes(option.value) ? '#149EF2' : '#ACBBCB'} />
                            }
                        </button>
                    )
                })}
            </div>
        </div>

    )
}