import { ModalContainer } from "components/common/ModalContainer"
import { HeaderComponent } from "components/publishShift/HeaderComponent"
import { FILTER_LABEL, FILTERS_LABEL } from "locales/shiftClaimList/es"
import { ActionButton } from "components/common/ActionButton"
import { FilterComponent } from "components/common/FilterComponent"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "store/types"
import { applySelectedOptions, clearSelectedOptionsInFilter, resetSelectedOptions, unselectOptionInFilter, selectOptionInFilter } from "store/actions/filtersActions"
import { applySelectedOptionsInProfessionalFilter, clearSelectedOptionsInProfessionalFilter, clearSelectedProfessionalSpecificFilter, resetSelectedOptionsInProfessionalFilter, selectOptionInProfessionalFilter, unselectOptionInProfessionalFilter } from "store/actions/professionalFiltersAction"

interface FilterProfessionalsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export default function FilterProfessionalsModal ({
  isOpen,
  onClose,
} : FilterProfessionalsModalProps) {
  const filters = useSelector((state: RootState) => state.professionalFilters.filters);
  const dispatch = useDispatch();

  return (
    <ModalContainer
      isOpen={isOpen}
      style={{
        width: '45%',
        maxWidth: '530px',
        height: '43%',
        maxHeight: '500px',
      }}
    >
      <div
        className="h-[500px] w-[530px] flex flex-col justify-between rounded-[8px] overflow-y-auto bg-white rounded-[16px] mx-auto"
      >
        <div>
          <HeaderComponent
            title={FILTERS_LABEL}
            onClose={() => {
              dispatch(resetSelectedOptionsInProfessionalFilter());
              onClose();
            }}
          />
          <div
            className="flex flex-col m-medium"
          >
            {
              filters.map((filter, index) => (
                <FilterComponent 
                  key={index} 
                  filter={filter}
                  selectOptionInFilter={(filter, option) => dispatch(selectOptionInProfessionalFilter(filter, option))}
                  unselectOptionInFilter={(filter, option) => dispatch(unselectOptionInProfessionalFilter(filter, option))}
                  clearSelectedOptionsInFilter={(filter) => dispatch(clearSelectedProfessionalSpecificFilter(filter))}
                />
              ))
            }
          </div>
        </div>
        <div
          className="flex flex-row justify-end items-center p-large border-t border-Divider-Default"
        >
          <div
            className="flex bg-Primary-Default text-white"
          >
            <ActionButton
              isDisabled={false}
              isLoading={false}
              onClick={() => {
                dispatch(applySelectedOptionsInProfessionalFilter());
                onClose();
              }}
            >
              <p
                className="w-[175px] action-regular text-center py-small"
              >
                {FILTER_LABEL}
              </p>
            </ActionButton>
          </div>
        </div>
      </div>
    </ModalContainer>
  )
}
