import { ShiftSummary } from "services/shifts-calendar"
import { CalendarMonth } from "./CalendarMonth";
import { useState } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "store/types";
import FiltersButton from "components/common/FiltersButton";

interface CalendarSummaryProps {
    currentMonth: string;  
    onMonthChange: (month: string) => void;  
    onDayPress: (day: string) => void;
    shiftSummary: ShiftSummary[];
    loading: boolean;
    openFilter: () => void;
}
export const CalendarSummary: React.FC<CalendarSummaryProps> = ({
    currentMonth,
    onMonthChange, 
    onDayPress,
    shiftSummary,
    loading,
    openFilter
}) => {
    const filters = useSelector((state: RootState) => state.filterShifts.filters);
    const numberOfAppliedFilters = filters.filter((f) => f.appliedOptions.length > 0).length;
    const { selectedDate } = useSelector((state: RootState) => state.calendarSummary)
    return (
        <div
            className="flex flex-col bg-white h-full justify-start pt-[20px] px-medium self-start border-r-[1px] border-solid border-Divider-Subtle w-[256px] space-y-small"
        >
            <div>
                <CalendarMonth
                    onDayPress={(day: string) => {
                        onDayPress(day)
                        if (moment(day).month() !== moment(currentMonth).month()) {
                            onMonthChange(moment(day).startOf('month').format('YYYY-MM-DD')); 
                        }
                    }}
                    calendarSummary={shiftSummary}
                    monthToRender={currentMonth}
                    daysSelected={[selectedDate.toString()]}
                    onMonthChange={(month: string) => {
                        onMonthChange(month); 
                    }}
                />
                {
                    filters && filters.length > 0 &&
                    <FiltersButton 
                        onClick={openFilter}
                        filters={filters}
                        numberOfAppliedFilters={numberOfAppliedFilters}
                    />
                }
            </div>
        </div>
    )
}