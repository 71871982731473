import { combineReducers } from 'redux';
import calendarSummaryReducer from './reducers/calendarSummary';
import publishShiftListReducer from './reducers/publishShiftList';
import activityShiftListReducer from './reducers/activityShiftList';
import { accountReducer } from './reducers/account';
import { appConfigurationReducer } from './reducers/appConfiguration';
import { shiftFiltersReducer } from './reducers/shiftFilters';
import  pendingRequestsReducer  from './reducers/pendingProfessionals'
import claimData from './reducers/claimsReducer';
import offerListReducer from './reducers/offerList';
import { professionalFiltersReducer } from './reducers/professionalFilterAction';


const rootReducer = combineReducers({
    calendarSummary: calendarSummaryReducer,
    publishShiftList: publishShiftListReducer,
    offerList: offerListReducer,
    activityShiftList: activityShiftListReducer,
    account: accountReducer,
    appConfiguration: appConfigurationReducer,
    filterShifts: shiftFiltersReducer,
    pendingRequests: pendingRequestsReducer,
    claimData,
    professionalFilters: professionalFiltersReducer,
});

export default rootReducer;
