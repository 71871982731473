import { NotificationsBadge } from "components/common/NotificationsBadge"
import { ProfilePicture } from "../../components/shiftDetails/ProfilePicture"
import { internalProfessionalDisplayName, FacilityDataFieldDefinition, InternalProfessional } from "../../types/internal"
import { CategoryTag } from "components/common/CategoryTag"

interface InternalProfessionalCardProps {
    professional: InternalProfessional
    isSelected?: boolean,
    onClick?: () => void,
    notifications?: number,
    dataFieldDefinitions: FacilityDataFieldDefinition[]
}

export const InternalProfessionalCard: React.FC<InternalProfessionalCardProps> = ({
    professional,
    isSelected,
    onClick,
    notifications,
    dataFieldDefinitions
}) => {

    const numberOfDataFields = dataFieldDefinitions.length + 2
    const gridCols = `grid-cols-${numberOfDataFields}`
    

    return (
        <div
            onClick={onClick}
            className={`flex flex-row  py-small px-small items-center ${isSelected ? 'bg-Action-Secondary text-Text-Inverse' : 'bg-white'} rounded-[16px] relative ${onClick ? 'cursor-pointer' : ''} overflow-hidden`}
            style={{
                minWidth: '200px',
            }}
        >
            <div className={`flex-1 grid ${gridCols} items-center justify-between space-x-small`}>
                <div className={`flex col-span-2 text-left text-nowrap items-center space-x-small`}>
                    <ProfilePicture profilePictureUrl={professional.profilePictureUrl} size={36} modality={null} />
                    {
                        professional.category ?
                            <CategoryTag
                                text={professional.category.acronym}
                            /> : null
                    }
                    <p className="subtitle-regular truncate "
                        title={internalProfessionalDisplayName(professional)}
                    >{internalProfessionalDisplayName(professional)}</p>
                </div>
                {
                    dataFieldDefinitions.map((dataFieldDefinition) => {
                        const selectedValue = professional.dataFields.find((dataField) => dataField.key === dataFieldDefinition.key)?.displayText
                        return (
                            <div
                                key={dataFieldDefinition.key}
                                className="flex col-span-1 text-left text-nowrap"
                                title={selectedValue?.split(', ').join('\n') || '-'} // Tooltip with full text
                            >
                                <p className="body-regular truncate">
                                    {
                                        selectedValue || '-'
                                    }
                                </p>
                            </div>
                        )
                    })
                }
            </div>
            <div
                className="w-[20px]"
            >
                {notifications && <NotificationsBadge
                    notifications={notifications}
                />}
            </div>

        </div>
    )
}